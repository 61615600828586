.select2-selection--single {
  background-color: $white;
  border-bottom: 1px solid $light-gray;
  border-radius: rem-calc(10);
  transition: opacity 0.5s ease-in-out;
  &:focus {
    outline: none;
    // border-bottom-color: $gam-purple;
  }

  .select2-selection__rendered {
    color: #444;
    font-size: rem-calc(16);
    font-weight: 500;
    line-height: rem-calc(40);
    padding-left: rem-calc(20);
    &:focus {
      outline: none;
    }
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: #999;
  }

  .select2-selection__arrow {
    height: rem-calc(20);

    position: absolute;

    top: 0;
    right: rem-calc(10);

    width: rem-calc(20);

    b {
      &:after {
        content: "";
    		display: inline-block;
    		width: rem-calc(10);
    		height: rem-calc(10);
        margin-left: rem-calc(4);
        margin-top: rem-calc(-3);
    		vertical-align: middle;
    		border-right: 1px solid $gam-purple;
    		border-bottom: 1px solid $gam-purple;
    		transform-origin: 0 center;
    		transform: rotate(45deg);
      }
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #eee;
    cursor: default;
    opacity: 0.5;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        &:after {
          margin-top: rem-calc(3);
      		border-right: 0;
      		border-bottom: 0;

      		border-top: 1px solid $gam-purple;
      		border-left: 1px solid $gam-purple;
        }
      }
    }
  }
}
