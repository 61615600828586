section.columned-text {
  overflow-x: hidden;

  .row {
    @include grid-row;
  }
  .intro {
    @include grid-column(12);
    @include breakpoint(large) {
      margin-bottom: rem-calc(30);
      @include grid-col-size(5);
      // max-width: rem-calc(380);
    }
    h2 {
      margin-bottom: rem-calc(30);
    }
  }
  .column {
    @include grid-column(1 of 1);
    @include breakpoint(medium) {
      @include grid-col-size(1 of 2);
    }
    h3 {
      color: $gam-purple;
      font-size: $global-font-size;
      font-weight: $global-weight-medium;
      margin-bottom: rem-calc(15);
    }
    &.image {
      > img,
      > picture {
        margin: 0 auto rem-calc(20);
        display: block;
        width: 100%;
      }
      > div {
        margin: 0 auto;
      }
    }
    &.text {
      @include breakpoint(xlarge) {
        &:first-child {
          padding-right: rem-calc(50);
        }
        &:last-child {
          padding-left: rem-calc(50);
        }
      }
      img {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);
      }
      .button {
        margin-top: rem-calc(20);
        margin-right: rem-calc(10);
      }
    }
  }
  &.light {
    .column {
      h3 {
        color: $white;
      }
    }
  }
}
