@media print {
  .show-for-print-only {
    display: block;
  }
  .reveal {
      background: white !important
  }
  main ul:not([class]) li:before {
    background: $gam-purple !important;
  }
  a:after {
    display: none;
  }
  blockquote {
    border: 0;
    p {
      font-size: rem-calc(21);
    }
  }
  [data-aos^="fade"] {
    transform: none !important;
    opacity: 1 !important;
  }
  .button {
    display: none !important;
  }
  .bg-image {
    background: none !important;
    &:before,
    &:after {
      display: none !important;
    }
  }
  main {
    section {
      padding-top: rem-calc(40);
      padding-bottom: rem-calc(40);
    }
  }
  header {
    .sticky-container {
      position: relative;
      height: auto !important;
    }
    .top-bar {
      position: relative !important;
      svg#gamma-logo-desktop {
        .st0 {
          fill: $gam-purple !important;
        }
        g path {
          fill: inherit !important;
        }
      }
      .top-bar-right {
        display: none;
      }
    }
  }
  section.hero-slide {
    .slide {
      min-height: 0 !important;
      background: none !important;
      @at-root body.page-template-page-contact & {
        background: none !important;
      }
      .bg-image {
        min-height: 0 !important;
      }
      .content {
        .text {
          margin: 0;
          max-width: none;
        }
      }
      .title {
        h1 {
          padding-top: rem-calc(40) !important;
        }
      }
    }
  }
  section.intro {
    .content {
      width: 50%;
    }
  }
  section.promo-listing {
    .row {
      .content.labelled {
        width: 100%;
        max-width: none;
      }
    }
    .bg-image {
      min-height: 0;
    }
  }
  ul.tabs {
    display: none;
  }
  .tabs-panel {
    display: block;
  }
  section.tabbed-content {
    .row {
      .intro {
        width: auto;
      }
    }
  }
  .row.signposts {
    .signpost img {
      display: none;
    }
    a {
      h3:after {
        display: none;
      }
    }
  }
  section.signpost {
    border: 1px solid $medium-gray;
    .row {
      .content {
        width: auto;
      }
    }
    &.testimonials {
      border-bottom: 1px solid $medium-gray;
      .row {
        .content {
          width: 50%;
        }
      }
    }
  }
  section.news {
    .row {
      .article {
        border: 1px solid $medium-gray;
      }
      .insights {
        .article {
          a {
            h2 {
              font-size: rem-calc(32);
            }
          }
        }
      }
    }
  }
  section.news.products .news .article {
    border: 1px solid $dark-gray;
    .bg-image {
      &:before {
        display: none;
      }
    }
  }
  section.showcase {
    .row {
      .content > img,
      .content > picture {
        display: none;
      }
    }
  }
  section.split-signposts {
    .signpost {
      a {
        h3 {
          color: $white !important;
          font-size: rem-calc(42) !important;
        }
      }
      .row {
        .content {
          z-index: 2;
          position: relative;
        }
      }
    }
    &.panels {
      .images .article {
        border: 1px solid $medium-gray;
        a img,
        .no-link img {
          display: none;
        }
      }
    }
  }
}
