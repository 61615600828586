section.split {
  padding: 0;
  .row {
    @include grid-row;
    position: relative;
    @include breakpoint(medium) {
      display: flex;
      &:before,
      &:after {
        display: none;
      }
    }
  }
  @include breakpoint(medium) {
    &.reverse {
      .row {
        flex-direction: row-reverse;
      }
    }
  }
  .content {
    @include grid-column(12);
    @include breakpoint(medium) {
      flex: 1 1 50%;
      @include grid-col-size(6);
      min-height: 30vw;
    }
  }
  &.text {
    .content {
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);
      @include breakpoint(medium) {
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(80);
      }
      @include breakpoint(large) {
        .text {
          /*max-width: rem-calc(400);*/
          max-width: 100%;
        }
      }
      a[href^="tel"] {
        display: block;
        font-weight: $global-weight-medium;
        font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        }
      }
      @include breakpoint(large) {
        .info {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-content: flex-end;
          align-items: flex-end;
          > .text {
            max-width: rem-calc(250);
            flex: 1 1 rem-calc(250);
          }
          > .link {
            flex: 1 1 0;
          }
        }
      }
    }
  }
  &.signposts {
    background: $white;
    @include breakpoint(medium) {
      .intro + & {
        .image {
          order: 1;
        }
        .content {
          order: 2;
        }
      }
    }
    @include breakpoint(large) {
      .intro + & {
        margin-top: rem-calc(-20);
      }
    }
    .content {
      padding: 0;
      display: flex;
      ul.signposts {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid $dark-gray;
        flex: 1 1 0;
        @include breakpoint(medium) {
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          border-top: 0;
        }
        li {
          border-bottom: 1px solid $dark-gray;
          flex: 1 1 0;
          display: flex;
          align-items: center;
          justify-content: stretch;
          &:last-child {
            border-bottom: 0;
          }
          a {
            flex: 1 1 0;
            color: $black;
            display: block;
            padding-top: rem-calc(30);
            padding-bottom: rem-calc(30);
            padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
            padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
            font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
            @include breakpoint(medium) {
              padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
              padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
              font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
            }
            &:hover {
              color: $gam-purple;
            }
            span {
              &:after {
                white-space: nowrap;
                content: "";
                display: inline-block;
                position: relative;
                top: rem-calc(-4);
                right: auto;
                margin-left: rem-calc(-20);
                margin-top: auto;
                border: 2px solid transparent;
                border-right-color: $gam-purple;
                border-top-color: $gam-purple;
                width: rem-calc(12);
                height: rem-calc(12);
                transform: rotate(45deg) translate(20px, -18px);
                vertical-align: baseline;
                @include breakpoint(small only) {
                  margin-left: rem-calc(-25);
                  top: rem-calc(-1);
                  transform: rotate(45deg) translate(20px, -18px) scale(0.8);
                }
              }
            }
          }
        }
      }
    }
  }
  .image {
    @include grid-column(12, 0);
    @include breakpoint(medium) {
      flex: 1 1 auto;
      @include grid-col-size(6);
      float: none !important;
      position: relative;
      overflow: hidden;
      img {
        height: auto;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        max-width: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
