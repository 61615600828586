@charset 'utf-8';

@import "settings";
@import "foundation";

@import "aos";

// @import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;

// @include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
// @include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Vendor
@import "vendor/select2/core", "vendor/slick/slick", "vendor/slick/slick-theme";

// Global
@import "global/accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";
@import "global/gutenberg";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";

// Components
@import "components/buttons";
// @import "components/cards";
// @import "components/featured-image";
// @import "components/dividers";
// @import "components/links";

// Patterns
@import "patterns/carousel";
@import "patterns/contact-us";
@import "patterns/contact-locations";
@import "patterns/gdpr-cookie";
@import "patterns/hero-slide";
@import "patterns/intro";
@import "patterns/columned-text";
@import "patterns/accordion";
@import "patterns/signpost";
@import "patterns/testimonials";
@import "patterns/locations";
@import "patterns/split-signposts";
@import "patterns/showcase";
@import "patterns/promo-listing";
@import "patterns/promo-listing-2";
@import "patterns/split";
@import "patterns/highlight-panel";
@import "patterns/case-studies";
@import "patterns/news";
@import "patterns/products";
@import "patterns/blog-articles";
@import "patterns/tabbed-content";
@import "patterns/user-guides";
@import "patterns/video-listing";

// Template specifics
@import "templates/single-product";
@import "templates/single-partner";
@import "templates/single-insight";
@import "templates/single-blog";
@import "templates/single-cta";
@import "templates/search-results";

@import "print";
