body.single-amplify,
body.single-insight {
  section.intro {
    background: $white;
    .row {
      align-items: flex-start;
    }
    .content {
      max-width: none;
      @include breakpoint(medium) {
        width: 48%;
      }
    }
    .right {
      max-width: none;
      @include breakpoint(medium) {
        width: 48%;
      }
    }
    @include breakpoint(medium) {
      &.reverse {
        .content {
          order: 2;
        }
        .right {
          order: 1;
          .content {
            margin-left: 0;
          }
        }
      }
    }
    .type-stat,
    .type-pullout {
      .content {
        float: none;
        padding: 0;
        color: $gam-purple;
        width: 100%;
        margin: rem-calc(20 0);
        @include breakpoint(medium) {
          max-width: rem-calc(350);
          margin: rem-calc(8) auto;
        }
      }
    }
    .type-pullout {
      p {
        font-weight: 500;
        line-height: 1.2;
        font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        }
      }
      .content:before {
        content: "";
        display: block;
        margin-bottom: rem-calc(20);
        width: rem-calc(60);
        height: rem-calc(8);
        background: linear-gradient(to right, #6800d2 0%, #9e29fd 100%);
      }
    }
    .type-stat {
      .content {
        max-width: rem-calc(220);
      }
      h2 {
        color: $gam-purple;
        display: inline-block;
        margin: 0;
        font-size: rem-calc(90);
        &:before {
          content: "";
          display: block;
          width: rem-calc(60);
          height: rem-calc(8);
          background: linear-gradient(to right, #6800d2 0%, #9e29fd 100%);
        }
      }
      p {
        font-weight: 500;
        font-size: rem-calc(map-deep-get($header-styles, small, h6, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h6, font-size));
        }
      }
    }
    &.light {
      .type-stat,
      .type-pullout {
        .content {
          color: $white;
        }
      }
      .type-stat {
        h2 {
          background: none;
          -webkit-text-fill-color: $white;
          &:before {
            background: $white;
          }
        }
      }
      .type-pullout {
        &:before {
          background: $white;
        }
      }
    }
  }
  section.columned-text {
    .intro {
      width: 100%;
      text-align: center;
      max-width: rem-calc(1000);
      margin: 0 auto rem-calc(40);
      float: none;
      @include breakpoint(large) {
        margin-bottom: rem-calc(80);
      }
    }
    @include breakpoint(medium) {
      .column.text {
        width: 48%;
      }
    }
  }
  section.large-pullout {
    background: $black;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 35vw;
    .content {
      position: relative;
      max-width: rem-calc(640);
      padding: rem-calc(40 70);
      margin: 0 auto;
      blockquote {
        position: relative;
        color: $white;
        line-height: 1.1;
        font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
        }
        &:before,
        &:after {
          color: $gam-purple;
          font-size: rem-calc(164);
          position: absolute;
          background: linear-gradient(to right, #6800d2 0%, #9e29fd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &:before {
          content: "“";
          left: rem-calc(-70);
          top: rem-calc(-80);
        }
        &:after {
          content: "”";
          right: rem-calc(-70);
          bottom: rem-calc(-150);
        }
        cite {
          margin-top: rem-calc(20);
          font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
          @include breakpoint(medium) {
            font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
          }
        }
      }
    }
  }
  section.related {
    background: $white;
  }
}
