section.split-signposts {
  &:not(.panels) {
    padding: 0;
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      &:before,
      &:after {
        display: none;
      }
    }
  }
  @include breakpoint(large) {
    .intro + & {
      margin-top: rem-calc(-20);
    }
  }
  .signpost {
    flex: 1 1 0;
    position: relative;
    display: flex;
    align-items: stretch;
    a {
      display: block;
      color: $white;
      position: relative;
      min-height: 64vw;
      flex: 1 1 0;
      img + .row,
      img + noscript + .row {
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          @include breakpoint(medium) {
            background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 90%);
          }
        }
      }
    }
    @include breakpoint(medium) {
      @include grid-col-size(6);
      flex: 1 0 50%;

      a {
        min-height: 0;
      }
    }
    .row {
      @include grid-row;
      .content {
        @include grid-column(12);
        padding-bottom: rem-calc(20);
        padding-top: rem-calc(20);
        @include breakpoint(medium) {
          padding-bottom: rem-calc(60);
          padding-top: rem-calc(60);
        }
      }
      h3 {
        font-size: rem-calc(40);
        @include breakpoint(large) {
          font-size: rem-calc(70);
        }
      }
    }
    img + .row,
    img + noscript + .row {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &.dark.no-img {
      a {
        color: $black;
      }
    }
    &.black {
      a {
        color: $black;
      }
      img + .row,
      img + noscript + .row {
        &:before {
          background: rgba(255, 255, 255, 0.4);
          @include breakpoint(medium) {
            background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 90%);
          }
        }
      }
      li:before {
        background-color: $gam-purple;
      }
    }
  }
  &.panels {
    @include breakpoint(small only) {
      padding-bottom: 0;
      .body-push.panels {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .title {
      padding-bottom: rem-calc(10);
      padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      @include breakpoint(medium) {
        padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      }
      @include breakpoint(large) {
        padding-bottom: rem-calc(40);
      }
    }
    .images {
      @include breakpoint(large) {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        flex-direction: row;
      }
      .article {
        @include breakpoint(medium) {
          padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
          padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
          padding-bottom: rem-calc(map-get($grid-margin-gutters, medium));
        }
        @include breakpoint(large) {
          display: flex;
          flex: 1 0 33.3333%;
          max-width: 33.3333%;
          align-content: stretch;
        }
        li {
          &:before {
            background: $white;
          }
        }
        a, .no-link {
          padding: rem-calc(20 10 10 10);
          color: $white;
          display: flex;
          flex-direction: column;
          width: 100%;
          .row {
            justify-self: stretch;
            flex-grow: 1;
            // flex: 1 0 0;
            display: flex;
            margin-left: rem-calc(20);
            margin-right: rem-calc(20);
            .content {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              width: 100%;
              .cta {
                margin-top: auto;
              }
            }
          }
          img {
            margin: rem-calc(0 20 10);
            width: rem-calc(80);
          }
          h3 {
            font-size: rem-calc(26);
            margin-bottom: rem-calc(40);
          }
          ul {
            margin-bottom: rem-calc(30);
          }
          span.button {
            margin-top: auto;
            background: $white;
            color: $gam-purple;
          }
          &:hover {
            span.button {
              background: $gam-yellow;
            }
          }
        }
        &.black {
          li {
            &:before {
              background: $black;
            }
          }
          a, .no-link {
            color: $black;
            &:hover {
              span.button {
                background: $gam-purple;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
