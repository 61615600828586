main.search-results {
  border-top: 1px solid $medium-gray;
  .title {
    padding-top: rem-calc(50);
    margin-bottom: rem-calc(50);
  }
  article {
    margin-bottom: rem-calc(50);
  }
  .row {
    .title,
    header,
    .content {
      max-width: rem-calc(1000) !important;
      padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      @include breakpoint(medium) {
        padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      }
    }
  }
}

.row.none {
  display: block !important;
  .title,
  header,
  .content {
    max-width: rem-calc(1000) !important;
    padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    @include breakpoint(medium) {
      padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    }
  }
  .content {
    width: auto !important;
    form {
      padding-bottom: rem-calc(50);
    }
  }
  button {
    background: transparent;
    color: $black;
    border: 0;
    &:hover {
      background: transparent;
    }
  }
}
