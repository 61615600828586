section.signpost {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 0;
  .bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative !important;
    min-height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.55);
      @include breakpoint(large) {
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 80%
        );
      }
      @include breakpoint(xlarge) {
        background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 80%
        );
      }
    }
    @at-root .parallax-ready &[data-enllax-ratio] {
      background-size: 170% auto;
      @include breakpoint(medium) {
        background-size: 150% auto;
      }
      @include breakpoint(xlarge) {
        background-size: 115% auto;
      }
      @include breakpoint(xxlarge) {
        background-size: 100% auto;
      }
    }
    &.no-overlay {
      &:before {
        display: none;
      }
    }
  }
  .row {
    @include grid-row;
    position: relative;
    width: 100%;
    @include breakpoint(large) {
      flex-shrink: 1;
      flex-basis: 100%;
      height: auto;
    }
    > .image {
      @include grid-column(12);
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);
      @include breakpoint(medium) {
        @include grid-col-size(4);
      }
      @include breakpoint(large) {
        @include grid-col-size(6);
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(80);
      }
      @include breakpoint(xlarge) {
        @include grid-col-size(4);
      }
    }
    .content {
      @include grid-column(12);
      padding-top: rem-calc(42);
      padding-bottom: rem-calc(42);
      color: $white;
      @include breakpoint(medium) {
        @include grid-col-size(9);
      }
      @include breakpoint(large) {
        @include grid-col-size(6);
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(80);
        .label {
          color: $gam-yellow;
        }
      }
      @include breakpoint(xlarge) {
        float: right;
        @include grid-col-size(5);
      }
      p {
        max-width: rem-calc(540);
      }
      .link {
        padding-top: rem-calc(30);
        .button {
          margin-right: rem-calc(20);
          &:first-child:not(:last-child) {
            background: $white;
            color: $gam-purple;
            &:hover {
              background-color: $gam-yellow;
            }
          }
          &:last-child {
            background: $gam-purple;
            color: $white;
            &:hover {
              background-color: $gam-yellow;
              color: $gam-purple;
            }
          }
        }
      }
      .signpost-logo {
        max-width: rem-calc(150);
        width: 100%;
        margin-top: rem-calc(20);
        @include breakpoint(large) {
          margin-top: rem-calc(30);
        }
      }
    }
    .logos {
      @include grid-column(12);
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);
      @include breakpoint(large) {
        @include grid-col-size(6);
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(80);
      }
      @include breakpoint(xlarge) {
        @include grid-col-size(5);
        float: right;
      }
    }
  }
  &.quote {
    .bg-image:before {
      display: none;
    }
    .row {
      .content {
        @include breakpoint(large) {
          float: left;
        }
      }
    }
  }
  &.large {
    .bg-image {
      background-position: center 0;
      &:before {
        display: none;
        @include breakpoint(small only) {
          display: block;
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
    &.white {
      .bg-image {
        &:before {
          @include breakpoint(small only) {
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .row {
      .content {
        float: left;
        h2 {
          font-size: rem-calc(40);

          @include breakpoint(medium) {
            font-size: rem-calc(28);
          }
          @include breakpoint(large) {
            font-size: rem-calc(60);
          }
          @include breakpoint(xlarge) {
            font-size: rem-calc(95);
          }
        }
      }
    }
  }
  &.large_icon {
    .row {
      > .image {
        padding-top: 0;
        padding-bottom: 0;
        display: none;
        @include breakpoint(large) {
          display: block;
        }
        @include breakpoint(xlarge) {
          @include grid-col-pos(1);
        }
        img {
          transform: scale(1.4);
        }
      }
      .content {
        float: left !important;
        @include breakpoint(large) {
          display: right !important;
        }
      }
    }
  }
  &.publication {
    .row {
      .content {
        h2 {
          font-size: rem-calc(40);
        }
        .label {
          color: $white;
        }
      }
      @include breakpoint(large) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:before,
        &:after {
          display: none;
        }
        .content {
          justify-self: flex-end;
          flex: 0 0 auto;
          h2 {
            font-size: rem-calc(60);
          }
        }
      }
      @include breakpoint(xlarge) {
        .content {
          h2 {
            font-size: rem-calc(95);
          }
        }
      }
    }
    &.white {
      ul.listing {
        a {
          color: $white;
          &:after {
            border-right-color: $white;
            border-top-color: $white;
          }
        }
      }
    }
    &.reverse {
      .row {
        @include breakpoint(large) {
          flex-direction: row-reverse;
          .content {
            justify-self: flex-start;
          }
        }
      }
    }
  }
  &.event {
    .bg-image {
      &:before {
        @include breakpoint(xlarge) {
          background: rgba(0, 0, 0, 0.6) !important;
        }
      }
    }
    .row {
      @include breakpoint(large) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40vw;
        &:before,
        &:after {
          display: none;
        }
        .content {
          float: none;
        }
      }
      .logos {
        @include breakpoint(large) {
          float: none;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .logo {
          max-width: 50%;
          flex: 0 1 50%;
          padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
          padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
          @include breakpoint(medium) {
            padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
            padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
          }
        }
      }
    }
    &.reverse {
      .row {
        @include breakpoint(large) {
          flex-direction: row-reverse;
          .content {
            justify-self: flex-start;
          }
        }
      }
    }
  }
  &.black,
  &.dark {
    .row {
      .content {
        color: $black;
        .label {
          color: $black;
        }
      }
    }
    .bg-image {
      &:before {
        background: rgba(255, 255, 255, 0.6);
        @include breakpoint(large) {
          background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.65) 0%,
            rgba(255, 255, 255, 0) 80%
          );
        }
      }
    }
  }
  &.light,
  &.white {
    .row {
      .content {
        .link {
          .button {
            background-color: $white;
            color: $gam-purple;
            &:hover {
              background-color: $gam-yellow;
            }
            &:first-child,
            &:last-child {
              background: $white;
              color: $gam-purple;
              &:hover {
                background-color: $gam-yellow;
              }
            }
          }
        }
      }
    }
  }
  &.reverse {
    &.large_icon {
      .row {
        > .image {
          @include breakpoint(xlarge) {
            @include grid-col-pos(-1);
          }
        }
      }
    }
    .bg-image {
      &:before {
        @include breakpoint(xlarge) {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(0, 0, 0, 0) 80%
          );
        }
      }
    }
    &.dark,
    &.black {
      .bg-image {
        &:before {
          @include breakpoint(xlarge) {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0.65) 0%,
              rgba(255, 255, 255, 0) 80%
            );
          }
        }
      }
    }
    .row {
      > .image {
        @include breakpoint(large) {
          float: right !important;
        }
      }
      .content {
        @include breakpoint(xlarge) {
          float: left !important;
        }
      }
    }
  }
  &.cta {
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:before,
      &:after {
        content: none;
      }
      > .image {
        padding-top: 0;
        padding-bottom: 0;
        display: none;
        @include breakpoint(large) {
          display: block;
        }
        @include breakpoint(xlarge) {
          @include grid-col-size(6);
        }
        img {
          &.sized {
            transform: none;
          }
        }
        &.sized img {
          transform: none;
        }
      }
    }
  }
  &.reverse {
    .row {
      flex-direction: row-reverse;
    }
  }
}

.sidebar section.signpost.cta {
  .bg-image {
    min-height: 0;
  }
  .body-push {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .row {
    .content {
      @include grid-col-size(12);
      text-align: center;
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(30);
      padding-right: rem-calc(30);
      @include breakpoint(xlarge) {
        @include grid-col-size(8);
        text-align: left;
      }
      .button {
        margin-right: 0;
      }
    }
    .image {
      @include grid-col-size(4);
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(30);
      padding-left: rem-calc(30);
    }
  }
  .single-cta & {
    max-width: rem-calc(400);
    margin: rem-calc(50) auto;
    clear: both;
  }
}