section.hero-slide {
  background: white;
  color: $white;
  padding: 0;
  position: relative;
  > .slide {
    display: none;
    &:first-child {
      display: block;

      @include breakpoint(medium) {
        display: flex;
      }
    }
    .row {
      a.button {
        margin-top: rem-calc(20);
      }
    }
  }
  .slide {
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    @include breakpoint(medium) {
      min-height: rem-calc(500);
    }
    @include breakpoint(xlarge) {
      height: calc(100vh - 100px);
      min-height: rem-calc(600);
      font-size: rem-calc(18);
      align-items: center;
      @at-root body.home & {
        min-height: 100vh;
      }
    }
    @at-root body[data-slug="products"] & {
      .content {
        .text {
          .title {
            @include breakpoint(medium) {
              max-width: rem-calc(450);
            }
            @include breakpoint(large) {
              max-width: rem-calc(550);
            }
          }
        }
      }
    }
    @at-root body[data-slug="solutions"] & {
      min-height: 0;
      max-height: rem-calc(600);
    }
    @at-root body[data-slug="find-a-partner"] & {
      min-height: 0;
      max-height: rem-calc(600);
    }
    @at-root body.page-template-page-contact & {
      background-image: url(../images/bg-dots.png), linear-gradient(80deg, #6800d2 60%, #30164a 100%) !important;
      background-repeat: no-repeat !important;
      background-size: auto 100% !important;
      background-position: right 0 !important;
    }
    > .row {
      a.button {
        background-color: $white;
        color: $gam-purple;
        &:hover,
        &:focus {
          background-color: $gam-yellow;
        }
      }
    }
    .content {
      position: relative;
      z-index: 2;
      width: 100%;
      min-height: 60vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include breakpoint(medium) {
        min-height: 0;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row;
      }
      .text {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: rem-calc(60 0 60);
        @include breakpoint(large) {
          max-width: 50%;
          padding: rem-calc(150 0 50);
        }
        &.black {
          color: $black;
        }
        @at-root body[data-slug="solutions"] & {
          @include breakpoint(medium) {
            max-width: 50%;
          }
          @include breakpoint(large) {
            padding-right: rem-calc(120);
          }
        }
      }
      .form {
        display: none;
        flex: 1 1 100%;
        justify-content: center;
        margin: rem-calc(30 0 40);
        @include breakpoint(large) {
          margin: rem-calc(150 0 50);
        }
        &.activated {
          display: flex;
          width: 100%;
        }
        @include breakpoint(medium) {
          max-width: 50%;
        }
        form {
          width: 100%;
          max-width: rem-calc(300);
          @include breakpoint(medium) {
            padding-top: rem-calc(50);
          }
          label {
            display: none;
          }
        }
      }
      > img,
      > picture {
        margin-top: rem-calc(120);
        margin-bottom: rem-calc(40);
        max-width: rem-calc(600);
        width: 45%;
        flex: 1 0 0;
        align-self: flex-start;
        height: auto;
        @include breakpoint(small only) {
          display: none;
        }
      }
      > div.embed-container {
        @include breakpoint(large) {
          width: 45%;
          flex: 1 0 0;
        }
        margin-top: rem-calc(120);
        margin-bottom: rem-calc(40);
        max-width: rem-calc(600);
        flex: 1 1 100%;
        max-width: 100%;
        width: 100%;
        align-self: flex-start;
        height: auto;
        > .embed-content {
          > h2 {
            text-align: center;
          }
          padding: 1.5rem 2.5rem;
          background: #fff;
          width: 75%;
          margin: auto;
          color: #000;
          box-shadow: 0 0.5rem 1rem #0000004d;
          border-radius: 0.25rem;
          @include breakpoint(small only) {
            width: 90%;
          }
          > .embed-iframe {
            background: #fff;
            height: 535px;
            padding: 1rem;
            border: 0;
            width: 100%;
            display: block;
            margin: auto;
          }
        }
      }
    }
    .bg-image {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative !important;
      display: flex;
      align-items: flex-end;
      padding-top: rem-calc(60);
      width: 100%;
      height: auto;
      @include breakpoint(medium) {
        min-height: rem-calc(500);
      }
      @include breakpoint(xlarge) {
        padding-top: 0;
        height: calc(100vh - 100px);
        min-height: rem-calc(600);
        font-size: rem-calc(18);
        align-items: center;
        @at-root body.home & {
          min-height: 100vh;
        }
      }
      &:not(.black) {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          @include breakpoint(large) {
            background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 80%),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 200px);
          }
        }
      }
      &.black {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.4);
          @include breakpoint(large) {
            background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 80%),
            linear-gradient(to bottom, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 200px);
          }
        }
      }
      &.no-overlay {
        &:before {
          background: transparent;
          @include breakpoint(large) {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 200px);
          }
        }
      }
      @at-root body[data-slug="solutions"] & {
        background: none !important;
        &:before {
          display: none;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: $gam-purple url(../images/bg-dots-solutions_v2.png) no-repeat;
          background-size: 100% auto;
          background-position: 50% 100%;
          z-index: 1;
          @include breakpoint(medium) {
            width: 50%;
          }
        }
        .title {
          h1 {
            padding-top: 0;
          }
        }
      }
      @at-root body[data-slug="find-a-partner"] & {
        background: none !important;
        &:before {
          display: none;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: $light-gray url(../images/dots-partners.png) no-repeat;
          background-size: 100% auto;
          background-position: 50% 100%;
          z-index: 1;
          @include breakpoint(medium) {
            width: 50%;
          }
        }
        .title {
          h1 {
            padding-top: 0;
          }
        }
      }
      @at-root .parallax-ready & {
        background-size: auto 140%;
        @include breakpoint(medium) {
          background-size: auto 130%;
        }
        @include breakpoint(large) {
          background-size: 115% auto;
        }
        @include breakpoint(xxlarge) {
          background-size: 100% auto;
        }
      }
      .links {
        margin-top: rem-calc(25);
      }
      .link {
        .cta {
          &:before {
            background: linear-gradient(to right, $gam-purple 0%, $gam-purple-light 100%);
          }
          &:after {
            border-left-color: $gam-yellow;
          }
        }
      }
    }
    .full-height {
      @include breakpoint(xlarge) {
        min-height: 100vh;
      }
    }
    .title {
      @include grid-column(1 of 1);
      > p {
        font-weight: 500;
        margin-bottom: rem-calc(10);
      }
      h1 {
        line-height: 1;
        margin-bottom: rem-calc(20);
        font-weight: $global-weight-medium;
        max-width: rem-calc(680);
        &:first-child {
          @include breakpoint(medium) {
            // padding-top: rem-calc(50);
          }
          @include breakpoint(large) {
            // padding-top: rem-calc(200);
            font-size: rem-calc(56);
          }
        }
        @at-root .home & {
          font-size: rem-calc(30);
          @include breakpoint(medium) {
            font-size: rem-calc(50);
          }
          @include breakpoint(large) {
            font-size: rem-calc(70);
            padding-top: rem-calc(150);
          }
        }
      }
      @include breakpoint(medium) {
        &.big-1 {
          h1 {
            font-size: rem-calc(120);
          }
        }
      }
    }
    .intro {
      @include grid-column(1 of 1);
      max-width: rem-calc(540);
      padding-bottom: rem-calc(20);
      img {
        max-width: rem-calc(150);
        width: 100%;
      }
    }
    .link {
      display: inline-block;
      padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      @include breakpoint(medium) {
        padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      }
      @at-root .home & {
        @include breakpoint(medium) {
          font-size: rem-calc(22);
        }
      }
      .cta {
        color: $white;
        position: relative;
        padding: rem-calc(8 50 8 0);
        margin-top: rem-calc(20);
        @include breakpoint(medium) {
          @at-root body:not(.home) & {
            margin-top: 0;
          }
        }
        margin-bottom: rem-calc(30);
        display: inline-block;
        transition: all 0.5s ease-in-out;
        &:before {
          transition: all 0.5s ease-in-out;
          position: absolute;
          right: 0;
          top: 0;
          content: "";
          display: block;
          width: rem-calc(40);
          height: rem-calc(40);
          background: $white;
          border-radius: 50%;
        }
        &:after {
          transition: all 0.5s ease-in-out;
          position: absolute;
          right: rem-calc(7);
          top: rem-calc(14);
          content: "";
          display: block;
          border: rem-calc(6) solid transparent;
          border-left-color: $gam-purple;
          transform: scaleX(1.8);
        }
        @at-root .home & {
          @include breakpoint(medium) {
            padding: rem-calc(12 100 12 0);
            &:before {
              right: rem-calc(10);
              top: 0;
              width: rem-calc(64);
              height: rem-calc(64);
            }
            &:after {
              border-width: rem-calc(10);
              right: rem-calc(20);
              top: rem-calc(22);
            }
          }
        }
        &:hover {
          color: $gam-yellow;
          &:before {
            background: $gam-yellow;
          }
          &:after {
            border-left-color: $gam-purple;
          }
        }
      }
    }
  }
  .hero-banner {
    background-color: rgba(0, 0, 0, 0.4);
    padding: rem-calc(30 0);
    width: 100%;
    @include breakpoint(medium) {
      position:absolute;
      bottom: 0;
      left: 0;
    }
    .row {
      display: flex;
      flex-direction: column;
      @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .banner-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: rem-calc(20);
      margin-bottom: rem-calc(20);
      @include breakpoint(medium) {
        margin-bottom: 0;
        padding-right: rem-calc(30);
      }
      svg {
        width: rem-calc(36);
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .banner-text {
        margin: 0;
        white-space: nowrap;
        display: inline-block;
      }
      .map-pin {
        display: inline-block;
        height: rem-calc(36)!important;
      }
    }
  }
}
