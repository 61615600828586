section.promo-listing-wrapper {
  padding: 0;
  background: $white;
}
section.promo-listing {
  padding: 0;
  .bg-image {
    min-height: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative !important;
    background-image: url(../images/bg-dots.png), linear-gradient(80deg, $gam-purple 60%, $gam-purple-extra-dark 100%);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right 0;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    @include breakpoint(large) {
      padding-top: rem-calc(80);
      padding-bottom: rem-calc(80);
    }
  }
  @at-root body[data-slug="solutions"] & {
    &:not(.contact) .bg-image {
      background-image: url(../images/bg-dots-solutions.png),
        linear-gradient(80deg, $gam-purple 60%, $gam-purple-extra-dark 100%);
      background-size: 100% auto;
      background-position: 0 100%;
      >.row {
        @include breakpoint(medium) {
          padding-top: rem-calc(20);
        }
        @include breakpoint(large) {
          padding-top: 0;
        }
      }
    }
  }
  @at-root body.single-case_study & {
    .bg-image {
      background-image: url(../images/bg-dots-master.png),
        linear-gradient(to top, $gam-purple-dark 10%, $gam-purple 100%);
      background-size: 100% auto;
      background-position: left 0 bottom 90%;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, rgba($gam-purple-dark, 0) 30%, rgba($gam-purple-dark, 1) 100%);
        z-index: 1;
      }
      > * {
        z-index: 2;
      }
    }
  }
  .row {
    @include grid-row;
    width: 100%;
    position: relative;
    justify-content: center;
    .content {
      color: $white;
      text-align: center;
      @include grid-column(12);
      .label {
        color: $gam-yellow;
      }
      h2 {
        font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
        }
      }
      &.labelled {
        text-align: left;
        @include breakpoint(medium) {
          max-width: rem-calc(400);
        }
        @include breakpoint(large) {
          @include grid-col-size(5);
        }
      }
      @at-root body.home & {
        h2 {
          font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
          @include breakpoint(medium) {
            font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
          }
        }
      }
    }
  }
  .button {
    background: $white;
    color: $gam-purple;
    display: inline-block;
    text-align: center;
    margin: rem-calc(20) auto 0;
    align-self: center;
    @include breakpoint(medium) {
      margin: rem-calc(60) auto 0;
    }
    &:hover {
      background: $gam-yellow;
      color: $gam-purple;
    }
  }
  &.contact {
    .row {
      .content {
        text-align: left;
        @include breakpoint(medium) {
          max-width: rem-calc(400);
        }
        @include breakpoint(large) {
          @include grid-col-size(5);
        }
      }
    }
    .bg-image {
      background: $gam-purple-dark url(../images/bg-contact.svg) no-repeat;
      background-size: auto 100%;
      background-position: right 20% top 0;
      min-height: 0;
      p:last-child a {
        @extend .button;
      }
    }
  }
  &.accordion-content {
    margin-left: rem-calc(-20);
    margin-right: rem-calc(-20);
  }
  &.stat {
    .row.signposts {
      .signpost {
        h3 {
          font-size: rem-calc(56);
          font-weight: 100;
        }
      }
    }
  }
}
.promo-listing.tab-wrapper {
  ul.tabs {
    margin-bottom: rem-calc(40);
    margin-top: rem-calc(20);
  }
  .accordion-item.is-active {
    .accordion-title {
      border-bottom-color: $white;
    }
  }
  .accordion-title {
    border-bottom-width: 1px;
  }
  .accordion-content {
    border: 0;
  }
}

.row.signposts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: rem-calc(30);
  &:before,
  &:after {
    display: none !important;
  }
  @include breakpoint(large) {
    margin-top: rem-calc(60);
  }
  .signpost {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    width: 50%;
    color: $white;
    margin-bottom: rem-calc(20);
    padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    @include breakpoint(medium) {
      max-width: 33.3333%;
      flex-basis:33.3333%;
      padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      @at-root body.home & {
        max-width: 25%;
        flex-basis:25%;
      }
    }
    @include breakpoint(large) {
      max-width: 20%;
      @at-root body.home & {
        max-width: 25%;
        h3 {
          font-size: rem-calc(18);
          max-width: rem-calc(200);
        }
      }
      @at-root body.single & {
        max-width: 20%;
      }
      @at-root body[data-slug="solutions"] & {
        max-width: 33.3333%;
        a,
        span.no-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            margin-right: rem-calc(20);
          }
          h3 {
            flex: 1 1 auto;
          }
        }
      }
      @at-root body[data-slug*="support"] & {
        max-width: 33.3333%;
        a,
        span.no-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            margin-right: rem-calc(20);
          }
          h3 {
            flex: 1 1 auto;
          }
        }
      }
    }
    img {
      margin-bottom: rem-calc(10);
      max-width: rem-calc(80);
      @include breakpoint(large) {
        margin-bottom: rem-calc(20);
        max-width: rem-calc(120);
      }
    }
    h3 {
      margin-bottom: rem-calc(10);
      font-size: rem-calc(14);
      font-weight: $global-weight-bold;
      color: $gam-yellow;
      @include breakpoint(medium) {
        font-size: rem-calc(16);
      }
    }
    p {
      font-size: rem-calc(14);
    }
  }
  a {
    color: $gam-yellow;
    display: block;
    h3 {
      &:after {
        white-space: nowrap;
        content: "";
        display: inline-block;
        position: relative;
        top: rem-calc(-2);
        right: auto;
        margin-left: rem-calc(-20);
        margin-top: auto;
        border: 2px solid transparent;
        border-right-color: $gam-yellow;
        border-top-color: $gam-yellow;
        width: rem-calc(10);
        height: rem-calc(10);
        transform: rotate(45deg) translate(20px, -18px);
        vertical-align: baseline;
        @include breakpoint(small only) {
          margin-left: rem-calc(-25);
          top: rem-calc(-1);
          transform: rotate(45deg) translate(20px, -18px) scale(0.8);
        }
      }
    }
    p {
      color: #fff;
    }
  }
  @include breakpoint(large) {
    &.cols-3 .signpost {
      max-width: 33.3333%;
    }
    &.cols-4 .signpost {
      max-width: 25%;
    }
    &.cols-5 .signpost {
      max-width: 20%;
    }
  }
}
