section.user-guides {
  h2 {
    @include grid-column(12);
    float: none !important;
    margin-bottom: rem-calc(20);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(40);
    }
  }
  .featured-guides-list {
    padding: 0 rem-calc(70);
    position: relative;
    .slide {
      @include grid-column(12);
      margin-bottom: rem-calc(20);
      @include breakpoint(medium) {
        @include grid-col-size(2);
        @include grid-column-end;
        margin-bottom: rem-calc(50);
      }
      .card-container {
        max-width: rem-calc(150);
        margin: 0 auto;
        img {
          box-shadow: 0 0 10px $dark-gray;
          max-width: rem-calc(100);
          margin: rem-calc(25 0 15 0);
          width: 100%;
        }
        p {
          max-width: rem-calc(150);
        }
      }
    }
  }
  ul.all-guides-list {
    border: none;
    .accordion-item {
      border-bottom: 0;
      .accordion-title {
        background-color: $gam-purple;
        color: $white;
        font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
        padding: rem-calc(10 20);
        &:before {
          border-bottom-color: $white;
          border-right-color: $white;
        }
      }
      .accordion-content {
        border: none;
        padding: rem-calc(30 20);
        overflow: hidden;
        .column {
          @include grid-column(12);
          @include breakpoint(medium) {
            @include grid-col-size(4);
            @include grid-column-end;
          }
          h3 {
            font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
            margin-bottom: rem-calc(20);
            @include breakpoint(medium) {
              margin-bottom: rem-calc(30);
            }
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style-type: none;
            }
          }
        }
      }
    }
  }

  .slick-arrow {
    top: 30%;
    transform: translateY(-50%);
  }
}
