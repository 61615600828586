// See _news.scss for parent styles
section.news.products {
  .news,
  .images {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .news {
    .article {
      flex: 1 1 100%;
      @include breakpoint(medium) {
        width: 50%;
        flex: 1 1 50%;
      }
      h3 {
        margin-top: rem-calc(8);
        margin-bottom: rem-calc(20);
        font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
        }
      }
      .icon {
        img {
          padding-right: rem-calc(20);
          width: 100%;
        }
      }
    }
  }
}
section.related-products {
  .row {
    display: block;
    &.columns {
      margin-top: rem-calc(map-get($grid-margin-gutters, small) / -2);
      @include breakpoint(medium) {
        margin-top: rem-calc(map-get($grid-margin-gutters, medium) / -2);
      }
    }
  }
  .images {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include breakpoint(large) {
      padding-bottom: rem-calc(40);
    }
    .article {
      flex: 1 1 100%;
      background: transparent;
      @include breakpoint(medium) {
        width: 33.3333%;
        flex: 0 1 33.3333%;
        margin-bottom: rem-calc(20);
        min-height: auto;
      }
      @include breakpoint(large) {
        width: 25%;
        flex: 0 1 25%;
        min-height: auto;
      }
      a {
        padding-top: rem-calc(map-get($grid-margin-gutters, small) / 2);
        @include breakpoint(medium) {
          padding-top: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        }
        padding-bottom: rem-calc(20);
        display: block;
        > p {
          width: auto;
        }
      }
      img {
        margin-bottom: rem-calc(20);
      }
      h3 {
        color: $gam-purple;
        font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size));
        }
        &:after {
          white-space: nowrap;
          content: "";
          display: inline-block;
          position: relative;
          top: rem-calc(-2);
          right: auto;
          margin-left: rem-calc(-20);
          margin-top: auto;
          border: 2px solid transparent;
          border-right-color: $gam-purple;
          border-top-color: $gam-purple;
          width: rem-calc(10);
          height: rem-calc(10);
          transform: rotate(45deg) translate(20px, -18px);
          vertical-align: baseline;
          @include breakpoint(small only) {
            margin-left: rem-calc(-25);
            top: rem-calc(-1);
            transform: rotate(45deg) translate(20px, -18px) scale(0.8);
          }
        }
      }
      &.light h3 {
        color: $white;
        &:after {
          border-right-color: $white;
          border-top-color: $white;
        }
      }
      &:hover {
        h3 {
          color: $black;
          &:after {
            border-right-color: $black;
            border-top-color: $black;
            margin-left: rem-calc(-10);
          }
        }
      }
      &.light:hover h3 {
        color: $gam-yellow;
        &:after {
          border-right-color: $gam-yellow;
          border-top-color: $gam-yellow;
        }
      }
    }
  }
}
