section.news {
  padding: 0;
  overflow: hidden;
  background: $white;
  .row {
    &:before,
    &:after {
      display: none;
    }
    overflow: hidden;
    @include breakpoint(medium) {
      display: flex;
    }
    .content {
      flex: 1 1 50%;
      flex-basis: 50%;
      max-width: rem-calc(440);
      @at-root .post-type-archive-event & {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      flex-basis: 100%;
      @include breakpoint(large) {
          order: 1;
          flex-basis: 50%;
        }
      }
    }
    .title,
    .search {
      @include grid-column(12);
      padding-top: rem-calc(20);
      @include breakpoint(large) {
        padding-top: rem-calc(80);
        padding-bottom: rem-calc(40);
      }
    }
    &.body-push + .row.body-push {
      .title {
        @include breakpoint(large) {
          padding-top: rem-calc(20);
        }
      }
    }
    .news,
    .insights {
      @include grid-column(1 of 1, 0);
      @include breakpoint(medium) {
        width: 50%;
        display: flex;
        flex-direction: column;
      }
    }
    .article {
      position: relative;
      background-color: $light-gray;
      flex: 1 0 0;
      display: flex;
      color: $black;
      justify-content: stretch;
      min-height: 60vw;
      @include breakpoint(medium) {
        min-height: 30vw;
        flex: 1 0 auto;
      }
      .bg-image {
        background-color: #ccc;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.4);
          @include breakpoint(large) {
            background: linear-gradient(to right, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 80%);
          }
          @at-root .post-type-archive-event & {
            @include breakpoint(large) {
              background: rgba(0, 0, 0, 0.6);
            }
          }
        }
        &.no-overlay {
          &:before {
            display: none;
          }
        }
        a {
          color: $black;
        }
        p.label {
          color: $gam-purple;
        }
      }
      a,
      span.no-link {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex: 1 0 100%;
        position: relative;
        z-index: 2;
        color: $black;
        padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
        padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(20);
        @include breakpoint(medium) {
          padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
          padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        }
        @include breakpoint(xlarge) {
          padding-top: rem-calc(80);
          padding-bottom: rem-calc(80);
        }
        .wrapper {
          width: 100%;
        }
        .row {
          padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2) !important;
          @include breakpoint(medium) {
            padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2) !important;
          }
          max-width: rem-calc(500);
          width: 100%;
        }
        & > h2,
        & > p,
        & > .wrapper .text {
          @include breakpoint(xxlarge) {
            @include grid-col-size(7 of 12);
          }
        }
      }
      p.label {
        color: $gam-purple;
      }
      h2 {
        margin-bottom: auto;
        font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        }
        @include breakpoint(large) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
        }
        + p {
          margin-top: rem-calc(20);
        }
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
        @include breakpoint(large) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
      span.button {
        margin-top: rem-calc(30);
        background: $gam-purple;
        color: $white;
        align-self: flex-start;
        &:hover {
          background: $white;
          color: $gam-purple;
        }
      }
      .icon {
        display: none;
        @at-root .post-type-archive-event & {
          // width: auto;
          // max-width: rem-calc(300);
          flex: 1 1 100%;
          float: none;
          width: rem-calc(100);
         @include breakpoint(large) {
          flex-basis: 50%;
          text-align: right;
          order: 2;
          width: rem-calc(240);
          }
          img {
            float: none;
          }
        }
        img {
          max-width: rem-calc(200);
        }
        @include breakpoint(small only) {
          display: block;
        }
        @include breakpoint(xlarge) {
          display: block;
          order: 2;
          flex-grow: 1;
          text-align: center;
        }
      }
      &.white {
        color: $white;
        a {
          color: $white;
        }
        p.label {
          color: $gam-yellow;
        }
        span.button {
          background: $white;
          color: $gam-purple;
          &:hover {
            background: $gam-yellow;
          }
        }
        .bg-image {
          &:before {
            background: rgba(0, 0, 0, 0.5);
            @include breakpoint(large) {
              background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 80%);
            }
          }
          a {
            color: $white;
          }
        }
      }
    }
    .insights {
      .article {
        a {
          h2 {
            margin-bottom: rem-calc(20);
            @include breakpoint(medium) {
              font-size: rem-calc(40);
            }
            @include breakpoint(large) {
              margin-bottom: rem-calc(60);
              font-size: rem-calc(70);
              line-height: 1.1;
            }
          }
          @include breakpoint(xlarge) {
            p {
              @include grid-col-size(7 of 12);
            }
          }
        }
      }
    }
  }
  @include breakpoint(medium) {
    &.no-insight {
      .row {
        .news {
          flex-direction: row;
          > div {
            max-width: 50%;
          }
        }
      }
    }
  }
  > .link {
    padding: rem-calc(20 0);
    text-align: center;
    @include breakpoint(medium) {
      padding: rem-calc(60 0);
    }
    .button {
      margin: 0;
    }
  }
}
