section.contact-details {
    h2 {
        @include grid-column(12); 
        margin-bottom: rem-calc(35);
    }
    .contact-details-list {
        margin: 0;
        padding: 0;
        .contact-details-block {
            @include grid-column(12);
            list-style-type: none;
            @include breakpoint(medium) {
                  @include grid-col-size(4);
            }
            h3 {
                color: $gam-purple;
                font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size));
                margin-bottom: rem-calc(20);
            }
            p {
                span {
                    color: $gam-purple;
                }
            }
        }
    }
}

section.contact-form {
    .content {
        @include grid-column(12);
        >.wpcf7 {
            padding-top: rem-calc(20);
            display: flex;
            flex-direction: column;
            margin-left:rem-calc(-15);
            margin-right:rem-calc(-15);
            width: 100%;
            @include breakpoint(medium) {
                flex-direction: row;
            }
            .wpcf7-form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }
            .input-field {
                @include grid-column(12); 
                @include breakpoint(medium) {
                    @include grid-col-size(6);
                }
                input, select, .select2-selection {
                    background-color: $light-gray;
                    border: none;
                    border-radius: rem-calc(15);
                    box-shadow: none;
                }
                [type='radio'] {
                    border: rem-calc(1) solid $black;
                    box-shadow: none;
                    margin-right: rem-calc(5);
                }
                [type='submit'] {
                    background-color: $gam-purple;
                    max-width: rem-calc(325);
                    margin-top: rem-calc(15);
                    padding-bottom: rem-calc(12);
                    padding-top: rem-calc(10);
                    width: 100%;
                }
                &:nth-last-child(3) {
                    margin-top: rem-calc(20);
                }
                &:nth-last-child(2) {
                    [type='submit'] {
                        margin-top: rem-calc(30);
                    }
                }
            }
        }
    }
}

