section.highlight-panels {
  padding: 0;
  .bg-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative !important;
    background-image: url(../images/bg-dots.png), linear-gradient(80deg, $gam-purple 60%, $gam-purple-extra-dark 100%);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right 0;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    @include breakpoint(large) {
      padding-top: rem-calc(80);
      padding-bottom: rem-calc(80);
    }
  }
  .highlight-columns {
    @include grid-column(12);
    display: flex;
    flex-direction: column;
    list-style-type: none;
    flex-wrap: wrap;
    margin: 0; 
    padding: 0;
    @include breakpoint(medium) {
      flex-direction: row;
    }
    .highlight-panel {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
      margin-bottom: rem-calc(30);
      @include breakpoint(medium) {
        flex-basis: 50%;
        padding-right: rem-calc(20);
      }
       @include breakpoint(large) {
        flex-basis: 25%;
        margin-bottom: 0;
        padding-right: rem-calc(30);
      }
      .label {
        color: $gam-yellow;
        margin-bottom: rem-calc(20);
      }
      .price, .chngecolor, .time {
        color: $white;
      }
      a.list-link {
        color: $white;
        display: block;
        font-size: rem-calc(21);
        line-height: 1.4em;
        position: relative;
        &:after {
          border: 1px solid transparent;
          border-bottom-color: $white;
          border-right-color: $white;
          content: '';
          display: inline-block;
          height: rem-calc(10);
          margin-left: rem-calc(8);
          width: rem-calc(10);
          transform: rotate(-45deg);
        }
      }
      .highlight-download-file {
        display: flex;
        flex-direction: column;
        @include breakpoint(medium) {
          flex-direction: row;
        }
        img {
          max-width: rem-calc(90);
          width: 100%;
        }
        .download-caption {
          color: $white;
          flex:1 1 50%;
          margin-left: rem-calc(20);
          @include breakpoint(medium) {
            flex-basis: 100%;
          }
        }
      }
      .highlight-cta {
        margin-top: auto;
        .cta {
          background-color: $white;
          color: $gam-purple;
          margin-top: rem-calc(40);
          &:hover {
            background-color: $gam-yellow;
          }
        }
      }
    }
  }
}
