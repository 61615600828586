body.single-cta {
  .full-width {
    padding: rem-calc(200 0 50);
    h3 {
      text-align: center;
    }
    text-align: center;
    .button {
      margin-top: rem-calc(50);
    }
    section {
      text-align: left;
    }
  }
  .sidebar {
    padding: rem-calc(200 0 50);
    h3 {
      text-align: center;
    }
    text-align: center;
  }
  section {
    text-align: left;
  }
}
