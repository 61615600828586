.body-push {
  padding-left: rem-calc(20) !important;
  padding-right: rem-calc(20) !important;
  @include breakpoint(xlarge) {
    padding-left: rem-calc(75) !important;
    padding-right: rem-calc(75) !important;
  }
}
.show-for-print-only {
  display: none;
}
h2,
.h2 {
  margin-bottom: rem-calc(20);
}
html:not([amp]) .no-hero {
  padding-top: rem-calc(50);
  padding-bottom: 0;
  @include breakpoint(large) {
    padding-top: rem-calc(150);
    padding-bottom: 0;
  }
}
body.busy {
  cursor: progress;
  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($gam-purple-dark, 0.9);
    z-index: 2000;
  }
}
.error404 {
  article {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
    padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    @include breakpoint(medium) {
      padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    }
  }
}
img[src*=".svg"] {
  height: inherit !important;
}
table {
  width: 100% !important;
}

p.label,
label {
  font-size: rem-calc(12);
  font-weight: $global-weight-medium;
  margin-bottom: rem-calc(5);
  @include breakpoint(medium) {
    font-size: rem-calc(14);
  }
}
.main-container {
  clear: both;
  // header + & {
  //   padding-top: rem-calc(200);
  // }
}
main section {
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);
  overflow: hidden;
  @include breakpoint(large) {
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(80);
  }
  &.light {
    color: $white;
    .button {
      background: $white;
      color: $gam-purple;
      &:hover {
        background: $gam-yellow;
      }
    }
    .label {
      color: $gam-yellow;
    }
  }
}
.button {
  font-weight: $global-weight-medium;
}
main ul:not([class]) {
  list-style: none;
  line-height: 1.4;
  li {
    position: relative;
    margin: rem-calc(4 0);
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: rem-calc(-15);
      top: rem-calc(8);
      width: rem-calc(6);
      height: rem-calc(6);
      border-radius: rem-calc(4);
      background: $gam-purple;
    }
    > ul {
      li:before {
        border-radius: 0;
        height: rem-calc(1);
        top: rem-calc(12);
      }
    }
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0 !important;
      line-height: 1.2;
    }
  }
}
main .light {
  ul:not([class]) {
    li {
      &:before {
        background: $white;
      }
    }
  }
}
blockquote {
  border-left: 0;
  padding: 0;
  &,
  & p {
    color: $black;
  }
  p {
    position: relative;
    line-height: 1.2;
    font-weight: $global-weight-medium;
    font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
    @include breakpoint(medium) {
      font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
    }
    &:before {
      content: "“";
      margin-left: -0.4em;
    }
    &:after {
      content: "”";
    }
  }
  cite {
    color: $gam-purple;
    font-style: normal;
    font-size: $global-font-size;
    margin-bottom: rem-calc(40);
    &:before {
      display: none;
    }
  }
  body.single-post &,
  body.single-blog & {
    p {
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
.reveal {
  padding: rem-calc(40);
}
.reveal.iframe {
  height: 90vh;
  .iframe-wrapper {
    height: 100%;
  }
}
.reveal-overlay {
  backdrop-filter: blur(0.25rem);
}
.reveal.gammapopup-container {
  height: 100%;
  @include breakpoint(medium) {
    border-radius: 0.25rem;
    height: 75%;
  }
  .gammapopup-content {
    height: 100%;
  }
}
div.search {
  form {
    padding-top: rem-calc(10);
    margin-right: rem-calc(-10);
    padding-right: 0 !important;
    @include breakpoint(small only) {
      padding-left: 0 !important;
    }
    input[type="text"] {
      border: 0;
      border-bottom: 1px solid $medium-gray;
      box-shadow: none;
    }
    button {
      background: transparent;
      border-bottom: 1px solid $medium-gray;
      padding-right: rem-calc(10);
      padding-left: rem-calc(10);
    }
  }
}

ul.listing {
  list-style: none;
  margin: 0 0 rem-calc(20);
  li {
    font-weight: 500;
    padding-bottom: rem-calc(8);
    a {
      &:after {
        white-space: nowrap;
        content: "";
        display: inline-block;
        position: relative;
        top: rem-calc(-2);
        right: auto;
        margin-left: rem-calc(-20);
        margin-top: auto;
        border: 2px solid transparent;
        border-right-color: $gam-purple;
        border-top-color: $gam-purple;
        width: rem-calc(10);
        height: rem-calc(10);
        transform: rotate(45deg) translate(20px, -18px);
        vertical-align: baseline;
        @include breakpoint(small only) {
          margin-left: rem-calc(-25);
          top: rem-calc(-1);
          transform: rotate(45deg) translate(20px, -18px) scale(0.8);
        }
      }
    }
  }
}

ul.share,
footer.footer-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
  }
  li.topnav-only {
    display: none;
  }
  a[href*="twitter.com"],
  a[href*="facebook.com"],
  a[href*="linkedin.com"],
  a[href*="youtube.com"] {
    text-indent: rem-calc(-9999);
    display: block;
    position: relative;
    width: rem-calc(32);
    height: rem-calc(32);
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: rem-calc(32);
      height: rem-calc(32);
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }
  a[href*="twitter.com"]:after {
    background: url(../images/icon_twitter.svg);
  }
  a[href*="facebook.com"]:after {
    background: url(../images/icon_facebook.svg);
  }
  a[href*="linkedin.com"]:after {
    background: url(../images/icon_linkedin.svg);
  }
  a[href*="youtube.com"]:after {
    background: url(../images/icon_youtube.svg);
  }
}
.form.filter {
  padding-bottom: rem-calc(40);
  h2 {
    font-size: $global-font-size;
    padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    @include breakpoint(medium) {
      padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    }
  }
  form {
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
    @include breakpoint(medium) {
      flex-wrap: nowrap;
    }
    p {
      padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      @include breakpoint(medium) {
        flex: 1 1 0;
        padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        &:last-child {
          flex: 0 1 0;
        }
      }
      label {
        display: none;
      }
      .select2-container--default .select2-selection--single {
        background: $light-gray;
      }
      button {
        margin-bottom: 0;
      }
    }
  }
}
.pagination {
  margin: rem-calc(40 0);
  .ajax-activated & {
    display: none;
  }
}
button.ajax-append {
  display: block;
  margin: rem-calc(40) auto;
}

.slick-arrow {
  background: $light-gray;
  color: $gam-purple;
  height: rem-calc(50);
  width: rem-calc(50);
  top: 50%;
  transform: translateY(-50%);
  &:before {
    border: rem-calc(3) solid transparent;
    height: rem-calc(16);
    width: rem-calc(16);
  }
}
.slick-next {
  left: auto;
  right: 0;
  &:before {
    border-top-color: $gam-purple;
    border-right-color: $gam-purple;
    margin-left: rem-calc(-7);
  }
}
.slick-prev {
  right: auto;
  left: 0;
  &:before {
    border-top-color: $gam-purple;
    border-left-color: $gam-purple;
    margin-left: rem-calc(7);
  }
}
.admin-bar {
  .select2-dropdown {
    transform: translateY(rem-calc(31));
  }
}

.wistia_responsive_padding {
  padding: 56.25% 0 0 0;
  position: relative;
}
.wistia_responsive_wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.wistia_embed {
  height: 100%;
  position: relative;
  width: 100%;
}
.wistia_swatch {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
  img {
    filter: blur(5px);
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
body.single-blog,
body.single-event,
body.single-case_study,
body.single-post {
  .breadcrumb_last {
    display: none;
  }
  .breadcrumbs {
    > span {
      display: inline-block;
      margin-right: -0.5em;
      clip-path: inset(0 5px 0 0); //to hide the last slash
    }
  }
}
html[amp] {
  *[data-aos] {
    transform: none;
    opacity: 1;
  }
}
section.html:first-child:last-child {
  .content {
    max-width: rem-calc(900);
    @include grid-column(12);
  }
}
.close-button {
  background-color: $gam-purple;
  color: $white;
  font-weight: 100;
  font-size: rem-calc(50);
  width: rem-calc(50);
  height: rem-calc(50);
  border-radius: 50%;
  top: rem-calc(30);
  right: rem-calc(30);
  span {
    display: block;
    margin-top: rem-calc(-4);
  }
  &:hover {
    background-color: $gam-yellow;
  }
}
