section.video-listing {
	border: none;
	&:nth-child(odd) {
		background-color: $light-gray;
	}
  	h2 {
  		@include grid-column(12);
  		margin-bottom: rem-calc(30);
  		@include breakpoint(medium) {
	  		margin-bottom: rem-calc(60);
	  	}
  	}
  	.video-list {
	    margin: 0;
	    padding: 0;
	    li {
	    	@include grid-column(12);
	    	@include grid-column-end;
	      	list-style-type: none;
	        @include breakpoint(medium) {
	            @include grid-col-size(6);
	        }
	        @include breakpoint(large) {
	            @include grid-col-size(4);
	        }
	        .video-title {
	        	color: $gam-purple;
	        	font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
	        	margin-bottom: rem-calc(20);
	        	&:after {
					content: '';
					border: rem-calc(2) solid transparent;
					border-right-color: $gam-purple;
					border-bottom-color: $gam-purple;
					display: inline-block;
					margin-left: rem-calc(10);
					width: rem-calc(10);
					height: rem-calc(10);
					transform: rotate(-45deg);
					transition: all 0.2s ease-in-out;
			    }
	        }
	        button {
				border-radius: 0;
	        	&:hover {
	        		cursor: pointer;
	        	}
	        }
	        .video-thumb {
	        	margin-bottom: rem-calc(30);
	        	width: 100%;
	        	@include breakpoint(medium) {
	        		margin-bottom: rem-calc(50);
	        	}
	        }
   		}
  	}
}