section.contact-locations {
    background-color: $gam-purple-dark;
    h2 {
        @include grid-column(12); 
        color: $white;
        margin-bottom: rem-calc(30);
    }
    .content{
        > p {
            @include grid-column(12); 
            color: $white;   
            max-width: rem-calc(500);
            margin-bottom: rem-calc(40);
        }
    }
    .contact-locations-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;
        .contact-location {
            @include grid-column(6);
            display: flex;
            flex-direction: column;
            list-style-type: none;
            margin-bottom: rem-calc(50);
            @include breakpoint(medium) {
                  @include grid-col-size(4);
            }
            @include breakpoint(large) {
                  @include grid-col-size(2);
                  margin-bottom: 0;
            }
            svg {
                fill: $white;
                margin-bottom: rem-calc(15);
                width: rem-calc(18);
            }
            h3 {
                color: $white;
                font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
                margin-bottom: rem-calc(20);
            }
            p {
                color: $white;
                margin-bottom: rem-calc(40);
            }
            .address-button {
                color: $white;
                font-weight: bold;
                margin-top: auto;
                position: relative;
                &:after {
                    border-bottom: rem-calc(1) solid $white;
                    border-right: rem-calc(1) solid $white;
                    content:'';
                    display: inline-block;
                    height: rem-calc(10);
                    width: rem-calc(10);
                    transform: rotate(-45deg);
                    margin-left: rem-calc(5);
                }
                &:hover, &:focus {
                    color: $gam-yellow;
                    &:after {
                        border-color: $gam-yellow;
                    }
                }
            }
        }
    }
}