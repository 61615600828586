// See _signpost.scss for parent styles
section.testimonials {
  background-color: $medium-gray;
   @include breakpoint(medium) {
    background-image: url(../images/bg-dots-testimonial.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 100% 50%;
  }  
  @at-root .single-case_study & {
    background-size: auto 100%;
    .row {
      .content {
        @include breakpoint(large) {
          @include grid-col-size(9);
        }
      }
    }
  }

  .row {
    &:before,
    &:after {
      display: none;
    }
    .slick-track {
      display: flex;
      align-items: stretch;
      &:before,
      &:after {
        display: none;
      }
    }
    .slide {
      height: auto;
      float: none;
      &:focus {
        outline: none;
      }
      @include breakpoint(large) {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        &:before,
        &:after {
          display: none;
        }
      }
      .link {
        padding-top: 0;
        .button {
          margin-right: rem-calc(10);
        }
      }
    }
    .content {
      @include breakpoint(large) {
        @include grid-col-size(6);
      }
      p.label {
        color: $black;
      }
    }
    .right {
      @include grid-column(12);
      display: flex;
      // flex-direction: column;
      // justify-content: flex-end;
      .image {
        display: none;
      }
      @include breakpoint(large) {
        @include grid-col-size(5);
        .image {
          display: block;
          width: 100%;
          align-self: flex-end;
          img {
            opacity: 0;
            transition: opacity 0.5s ease-out;
            &.lazyloaded {
              opacity: 1;
              transition: opacity 0.5s ease-out;
            }
          }
        }
      }
    }
  }
  .row.logos {
    width: auto;
    float: none !important;
    justify-content: flex-start;
    padding-bottom: 0;
    margin-left: rem-calc(map-get($grid-margin-gutters, small) * -1);
    margin-right: rem-calc(map-get($grid-margin-gutters, small) * -1);
    @include breakpoint(medium) {
      margin-left: rem-calc(map-get($grid-margin-gutters, medium) * -1);
      margin-right: rem-calc(map-get($grid-margin-gutters, medium) * -1);
    }
    .logo {
      @include breakpoint(medium) {
        max-width: 33.3333%;
      }
    }
  }
}
