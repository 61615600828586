section.case-studies {
  .row {
    @include grid-row;
    position: relative;
    .content {
      @include grid-column(12);
      @include breakpoint(medium) {
        @include grid-col-size(6);
      }
      @include breakpoint(large) {
        @include grid-col-size(5);
      }
      h2 {
        margin-bottom: rem-calc(20);
      }
    }
  }
  .link {
    margin: rem-calc(20 0);
    text-align: center;
  }
  .logos.carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    &.slick-slider {
      padding-left: rem-calc(70);
      padding-right: rem-calc(70);
    }
  }
  &.gold {
    background: $light-gray;
  }
}
.row.logos,
.row > .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-x: hidden;
  @at-root body.page-template-page-partners & {
    padding-top: 0;
    padding-bottom: 0;
  }
  &:before,
  &:after {
    display: none !important;
  }
  .slick-track {
    display: inline-block;
    // overflow-x: hidden;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // align-items: center;
    &:before,
    &:after {
      display: none !important;
    }
    .logo {
      display: inline-block!important;
      width: auto;
      img {
        @include breakpoint(medium) {
          padding-right: rem-calc(50);
        }
        @include breakpoint(large) {
          padding-right: rem-calc(90);
        }
      }
    }
  }
  .logo {
    display: flex;
    align-items: center;
    max-width: 50%;
    flex-basis: 50%;
    flex-grow: 1;
    padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    width: 100%;
    @include breakpoint(medium) {
      flex-basis: 25%;
      max-width: 25%;
      padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    }
    @include breakpoint(large) {
      flex-basis: 20%;
      max-width: 20%;
    }
    a {
      display: block;
      padding: rem-calc(10 0 40);
      max-width: rem-calc(115);
      margin: 0 auto;
      @include breakpoint(medium) {
        max-width: rem-calc(150);
      }
    }
  }
}
