.footer-container {
  @include xy-grid-container;
  max-width: none;
  padding-top: 0;
  background: $light-gray;
}

.footer-grid {
  @include xy-grid;
  padding: rem-calc(20) 0;
  @include breakpoint(large) {
    padding: rem-calc(80) 0;
  }
  
  .logo {
    @include grid-col-size(2 of 12);
    margin-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    margin-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    @include breakpoint(medium) {
      margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    }
    svg {
      max-width: rem-calc(150);
    }
  }

  section {
    @include xy-cell();
    font-size: rem-calc(13);
    @include breakpoint(large) {
      @include xy-cell(auto);
    }
    a {
      color: $black;
      &:hover {
        color: $gam-purple;
      }
    }
    h6 {
      font-size: rem-calc(13);
    }
    &.widget_nav_menu {
      flex: 1 1 auto;
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(30);
      margin-left: 0;
      margin-right: 0;
      .menu-main-menu-container {
        float: right;
        width: 100%;
        .menu {
          justify-content: flex-end;
          align-content: stretch;
          li {
            flex: 1 1 100%;
            @include breakpoint(medium) {
              flex-basis: 0;
            }
            a {
              font-size: rem-calc(13);
              padding-left: 0;
              padding-right: 0;
              padding-top: rem-calc(5);
              padding-bottom: rem-calc(5);
            }
          }
          > li {
            padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
            padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
            @include breakpoint(medium) {
              padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
              padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
            }
            > a {
              font-weight: $global-weight-medium;
            }
            li {
              display: none;
              @include breakpoint(medium) {
                display: list-item;
                &.top-nav-only {
                  display: none;
                }
              }
            }
            &.footer-only:first-child {
              border-right: 1px solid $gam-purple;
              padding-right: 0;
              margin-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
              @include breakpoint(medium) {
                margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
              }
              @include breakpoint(small only) {
                border-right: 0;
              }
              a {
                color: $gam-purple;
              }
            }
          }
          .sub-menu .sub-menu {
            display: none;
          }
        }
      }
      & + section {
        flex: 1 1 100%;
        border-bottom: 1px solid $dark-gray;
        margin-bottom: rem-calc(20);
        .textwidget ul {
          margin-left: rem-calc(-5);
          li {
            display: inline-block;
          }
        }
        & + section > div {
          @include breakpoint(medium) {
            display: flex;
            justify-content: space-between;
          }
          ul {
            margin: rem-calc(0 -5);
            li {
              display: inline-block;
              border-right: 1px solid $dark-gray;
              padding-right: rem-calc(5);
              padding-left: rem-calc(5);
              @include breakpoint(medium) {
                padding-right: rem-calc(10);
                padding-left: rem-calc(10);
              }
              &:last-child {
                border-right: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
