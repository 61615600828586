section.accordion {
  .content {
    @include grid-column(12);
    .title h2 {
      margin-bottom: rem-calc(40);
      @include breakpoint(medium) {
        margin-bottom: rem-calc(80);
      }
    }
  }
  ul.accordion {
    background: transparent;
    border-top: 1px solid $black;
  }
  .accordion-item {
    border-bottom: 1px solid $black;
    &:last-child {
      > .accordion-title,
      > :last-child {
        border-bottom: 0;
      }
    }
  }
  .accordion-title,
  .accordion-content {
    background: transparent;
    border: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .accordion-title {
    font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size));
    margin-right: rem-calc(20);
    padding-right: rem-calc(50);
    @include breakpoint(medium) {
      line-height: 1.2;
      font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size));
    }
    &:before {
      content: "";
      margin-top: rem-calc(-15);
      border: 1px solid transparent;
      border-right-color: $black;
      border-bottom-color: $black;
      width: rem-calc(20);
      height: rem-calc(20);
      transform: rotate(45deg);
      vertical-align: baseline;
      transition: all 0.2s ease-in-out;
    }
  }
  .is-active > .accordion-title:before {
    transform: rotate(-135deg);
    margin-top: 0;
  }
  &.light {
    ul.accordion {
      border-top-color: $white;
    }
    .accordion-title:before {
      border-right-color: $white;
      border-bottom-color: $white;
    }
    .accordion-item {
      border-bottom-color: $white;
    }
    .accordion-title,
    .accordion-content {
      color: $white;
      border-bottom-color: $white;
    }
  }
  &.faq {
    padding: 0;
    .content {
      padding: 0;
    }
    .accordion.cats {
      border-top: none;
      margin-bottom: 0;
      > .accordion-item {
        &:last-child {
          border-bottom-color: transparent;
        }
        > .accordion-title {
          &:before {
            width: rem-calc(25);
            height: rem-calc(25);
            right: rem-calc(35);
            @include breakpoint(xlarge) {
              right: rem-calc(105);
            }
          }
        }
        h2 {
          margin: 0;
          padding: rem-calc(50 0);
          padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
          padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
          @include breakpoint(medium) {
            padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
            padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
          }
        }
        .content {
          padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
          padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
          @include breakpoint(medium) {
            padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
            padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
          }
        }
        .accordion-item.is-active {
          .accordion-title {
            border-bottom-color: transparent;
          }
        }
        .accordion-title {
          color: $black;
          font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
          padding: 0;
        }
        .accordion-content {
          .accordion {
            border: none;
            padding: rem-calc(0 0 25 0);
            .accordion-item {
              .accordion-title {
                padding: rem-calc(15 0);
                font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size));
                border-bottom-color: transparent;
              }
              &:first-child {
                .accordion-title {
                  border-top: rem-calc(1) solid $black;
                }
              }
              .accordion-content {
                border: none;
                color: $gam-purple;
              }
              // &.is-active {
              //   .accordion-content {
              //     border-bottom: rem-calc(1) solid $black;
              //   }
              // }
            }
          }
        }
        &.is-active {
          > .accordion-title {
            border-bottom: rem-calc(1) solid transparent;
          }
        }
      }
    }
  }
}
.accordion-item:first-child > :first-child,
.accordion-item:last-child > :last-child,
:last-child:not(.is-active) > .accordion-title {
  border-radius: 0;
}
