section.carousel-slide {
  .row {
    h2 {
      display: inline-block;
    }
    .cta {
      display: inline-block;
    }
    .carousel {
      display: flex;
      flex-direction: row;
      width: 100%;
      .slide {
        .card-container {
          img {
            height: rem-calc(150);
            width: auto;
          }
        }
      }
    }
  }
}