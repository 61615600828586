@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../fonts/fontawesome" !default;
$slick-font-family: 'Font Awesome 5 Free' !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: get-color(primary) !default;
$slick-dot-color: get-color(secondary) !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.85 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
  z-index: 3;
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  top: 0;
  z-index: 4;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: get-color(primary);
  color: $white;
  padding: 0;
  border: none;
  outline: none;
  @include breakpoint(medium) {
    top: auto;
    bottom: 30px;
  }
  &:hover, &:focus {
      outline: none;
      &:before {
          opacity: $slick-opacity-on-hover;
      }
  }
  &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
  }
  &:before {
      font-family: $slick-font-family;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      color: $white;
      opacity: $slick-opacity-default;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  right: 90px;
  @include breakpoint(medium) {
    right: auto;
    left: rem-calc(540);
  }
  @include breakpoint(xlarge) {
    left: rem-calc(670);
  }
    [dir="rtl"] & {
    }
    &:before {
      content: "";
  		display: inline-block;
  		width: rem-calc(9);
  		height: rem-calc(9);
  		vertical-align: middle;
  		border-top: 1px solid $white;
  		border-left: 1px solid $white;
  		transform-origin: center;
  		transform: rotate(-45deg);
        [dir="rtl"] & {
      		border-top: 1px solid $white;
      		border-right: 1px solid $white;
          border-left: transparent;
          transform: rotate(45deg);
        }
    }
}

.slick-next {
  right: 30px;
  @include breakpoint(medium) {
    right: auto;
    left: rem-calc(600);
  }
  @include breakpoint(xlarge) {
    left: rem-calc(730);
  }
    [dir="rtl"] & {
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 30px;
      width: 1px;
      background: $white;
      z-index: 2;
    }
    &:before {
      content: "";
  		display: inline-block;
  		width: rem-calc(9);
  		height: rem-calc(9);
  		vertical-align: middle;
  		border-top: 1px solid $white;
  		border-right: 1px solid $white;
  		transform-origin: center;
  		transform: rotate(45deg);
        [dir="rtl"] & {
      		border-top: 1px solid $white;
      		border-left: 1px solid $white;
          border-right: transparent;
          transform: rotate(45deg);
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    // margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  z-index: 3;
  bottom: rem-calc(10);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-end;
  list-style: none;
  margin: 0;
  left: auto;
  right: 0;
  @extend .body-push;
  margin-left: rem-calc((map-get($grid-column-gutter,small)/2)-2);
  margin-right: rem-calc((map-get($grid-column-gutter,small)/2)-2);
  @include breakpoint(medium) {
    bottom: rem-calc(50);
    margin-left: rem-calc((map-get($grid-column-gutter,medium)/2)-2);
    margin-right: rem-calc((map-get($grid-column-gutter,medium)/2)-2);
  }
  li {
    position: relative;
    display: inline-block;
    margin: 0 2px;
    padding: 0;
    &:before {
      display: none;
  }
  cursor: pointer;
  button {
    border: 1px solid get-color(primary);
    background: transparent;
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    cursor: pointer;
    &:hover, &:focus {
        outline: none;
        background: get-color(primary);
        opacity: $slick-opacity-on-hover;
        // &:before {
            //     opacity: $slick-opacity-on-hover;
            // }
        }
        // &:before {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     content: $slick-dot-character;
            //     width: 20px;
            //     height: 20px;
            //     font-family: $slick-font-family;
            //     font-size: $slick-dot-size;
            //     line-height: 20px;
            //     text-align: center;
            //     color: $slick-dot-color;
            //     opacity: $slick-opacity-not-active;
            //     -webkit-font-smoothing: antialiased;
            //     -moz-osx-font-smoothing: grayscale;
            // }
        }
        &.slick-active button {
          background: get-color(primary);
        }
    }
}
