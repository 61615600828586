// See _signpost.scss for parent styles
section.showcase {
  .row {
    .content {
      > img,
      > picture {
        width: rem-calc(80);
      }
    }
  }
  .signposts {
    position: relative;
    margin: 0;
    padding: rem-calc(20 0);
    justify-content: center;
    @include breakpoint(medium) {
      padding: rem-calc(40 0 60 0);
    }
    &:before,
    &:after {
      display: block !important;
      content: "";
      position: absolute;
      top: rem-calc(-20);
      width: 50%;
      background-color: inherit;
      padding-top: rem-calc(20);
    }
    &:before {
      right: 50%;
      -ms-transform-origin: 100% 100%;
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      -ms-transform: skewX(45deg);
      -webkit-transform: skewX(45deg);
      transform: skewX(45deg);
    }
    &:after {
      left: 50%;
      -ms-transform-origin: 0 100%;
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
      -ms-transform: skewX(-45deg);
      -webkit-transform: skewX(-45deg);
      transform: skewX(-45deg);
    }
    .signpost {
      p {
        margin-bottom: 0;
      }
    }
  }
  &.dark,
  & .signposts.dark {
    .signpost {
      color: $black;
      a,
      h3 {
        color: $gam-purple;
        p {
          color: $black;
        }
      }
      h3:after {
        border-right-color: $gam-purple;
        border-top-color: $gam-purple;
      }
    }
  }
  &:nth-of-type(2n + 1) {
    .row {
      .content {
        float: left;
      }
    }
    .bg-image:before {
      @include breakpoint(large) {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 80%);
      }
    }
  }
}
