#moove_gdpr_cookie_info_bar {
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);
  padding-left: rem-calc(25)!important;
  padding-right: rem-calc(25)!important;
  @include breakpoint(xlarge) {
    padding-left: rem-calc(75)!important;
    padding-right: rem-calc(75)!important;
  }
  .moove-gdpr-info-bar-container {
    padding: 0!important;
    width: 100%;
    .moove-gdpr-info-bar-content {
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 0!important;
      padding-right: 0!important;
      .moove-gdpr-cookie-notice {
        flex: 1 1 auto;
        @include breakpoint(large) {
          padding-left: rem-calc(15);
          padding-right: rem-calc(15);
        }
        p {
          color: $black!important;
          font-size: rem-calc(16);
          font-weight: 400;
          line-height: rem-calc(26);
          margin-bottom: rem-calc(20);
          @include breakpoint(medium) {
            margin-bottom: 0;
          }
        }
      }
      .moove-gdpr-button-holder {
        flex: 1 1 auto;
        padding-left: 0!important;
        @include breakpoint(large) {
          padding-left: rem-calc(15)!important;
          padding-right: rem-calc(15)!important;
        }
      }
    }
  }
  &.moove-gdpr-dark-scheme {
    background-color: $gam-yellow!important;
    border-color: transparent!important;
  }
}