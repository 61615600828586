// See _news.scss for parent styles
section.news.blog {
  border-top: 1px solid transparent;
  .news {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .article {
      flex: 1 1 100%;
      @include breakpoint(medium) {
        min-width: 50%;
        flex: 1 1 50%;
      }
      &:nth-child(5n + 1) {
        background: $gam-purple-dark;
        &:not(.black) {
          @extend .white;
        }
      }
      &:nth-child(5n + 2) {
        background: $light-gray;
      }
      &:nth-child(5n + 3) {
        background: $gam-yellow;
      }
      &:nth-child(5n + 4) {
        background: $dark-gray;
      }
      &:nth-child(5n + 5) {
        background: $black;
        &:not(.black) {
          @extend .white;
        }
      }
    }
    &.upcoming {
      .article:first-child {
        flex: 1 0 100%;
        min-height: 40vw;
        a {
          justify-content: center;
          .wrapper {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.related {
    border-top-color: $medium-gray;
  }
  &.simple {
    @include grid-row;
    padding: rem-calc(20 0);
    @include breakpoint(large) {
      padding: rem-calc(80 0);
    }
    .title {
      padding-top: 0;
    }
    .news {
      @include grid-column(12);
      min-height: auto;
      .article {
        background: $white;
        min-height: auto;
        @include breakpoint(medium) {
          min-width: 100%;
        }
        a {
          padding: 0;
          .wrapper {
            flex-direction: column;
            margin: 0;
          }
          p.label {
            color: $black !important;
            margin: 0;
          }
          h2 {
            color: $gam-purple;
            margin-bottom: rem-calc(20);
            position: relative;
            width: 100% !important;
            &:after {
              border: 2px solid transparent;
              border-bottom-color: $gam-purple;
              border-right-color: $gam-purple;
              content: "";
              display: inline-block;
              height: rem-calc(14);
              margin-left: rem-calc(10);
              width: rem-calc(14);
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .link {
      @include grid-column(12);
      padding: rem-calc(20 0 0 0);
      text-align: left;
      @include breakpoint(medium) {
        padding: rem-calc(80 0 0 0);
      }
      a {
      }
    }
  }
}
