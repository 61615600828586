section.contact-group-locations {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  ul.contact-address-list {
    list-style-type: none;
    margin: 0;
    .contact-card-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .contact-card-column {
        @include breakpoint(large) {
          @include grid-col-size(6);
        }
        .content {
          @include grid-column(12);
        }
      }
      .contact-card-map {
        order: 2;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .acf-map {
          width: 100%;
          height: 72vw;
          border: #ccc solid 1px;
          margin: 0;
          @include breakpoint(medium) {
            height: rem-calc(400);
          }
          @include breakpoint(large) {
            height: 28vw;
            min-height: rem-calc(425);
          }
          img {
            max-width: inherit !important;
          }
        }
      }
      .contact-card-address {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 1;
        padding: rem-calc(40 20);
        position: relative;
        @include breakpoint(medium) {
          padding: rem-calc(60 20);
        }
        @include breakpoint(large) {
          padding: rem-calc(80 20);
        }
        p {
          line-height: 1.27em;
          font-size: rem-calc(18);
          letter-spacing: rem-calc(-0.03);
          position: relative;
          z-index: 1;
          @include breakpoint(medium) {
            font-size: rem-calc(26);
          }
        }
        a {
          font-size: rem-calc(16);
          letter-spacing: rem-calc(-0.03);
          &:after {
            content: "";
            display: inline-block;
            height: rem-calc(8);
            width: rem-calc(8);
            margin-bottom: rem-calc(2);
            margin-left: rem-calc(4);
            transform: rotate(-45deg);
          }
          &:hover {
            &:after {
              margin-left: rem-calc(10);
            }
          }
        }
        &:after {
          // background-color: red;
          content: "";
          height: rem-calc(40);
          width: rem-calc(40);
          position: absolute;
          bottom: rem-calc(-20);
          left: 50%;
          transform: translateX(-50%) rotate(-45deg);
          @include breakpoint(large) {
            bottom: unset;
            left: calc(100% - 20px);
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
      &[style*="#0a0a0a"] {
        .contact-card-address:after {
          background-color: #0a0a0a;
        }
      }
      &[style*="#b2b2b2"] {
        .contact-card-address:after {
          background-color: #b2b2b2;
        }
      }
      &[style*="#f2f2f2"] {
        .contact-card-address:after {
          background-color: #f2f2f2;
        }
      }
      &[style*="#6800d2"] {
        .contact-card-address:after {
          background-color: #6800d2;
        }
      }
      &[style*="#9e29fd"] {
        .contact-card-address:after {
          background-color: #9e29fd;
        }
      }
      &[style*="#440099"] {
        .contact-card-address:after {
          background-color: #440099;
        }
      }
      &[style*="#fbe200"] {
        .contact-card-address:after {
          background-color: #fbe200;
        }
      }
      &[style*="#fafafa"] {
        .contact-card-address:after {
          background-color: #fafafa;
        }
      }
      &:nth-child(odd) {
        .contact-card-address {
          @include breakpoint(large) {
            order: 2;
            &:after {
              right: unset;
              left: rem-calc(-20);
            }
          }
        }
        .contact-card-map {
          @include breakpoint(large) {
            order: 1;
          }
        }
      }
    }
  }
}
