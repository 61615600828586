.select2-selection--multiple {
  background-color: $white;
  border: 1px solid rgba($medium-gray,0);
  border-radius: 0;
  cursor: text;
  &:focus {
    outline: none;
    border: 1px solid rgba($medium-gray,1);
  }

  .select2-selection__rendered {
    box-sizing: border-box;
    font-size: rem-calc(16);
    list-style: none;
    margin: 0;
    padding: 0 10px;
    width: 100%;

    li {
      list-style: none;
    }
    &:focus {
      outline: none;
    }
  }

  .select2-selection__placeholder {
    color: #999;

    margin-top: 5px;

    float: left;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
  }

  .select2-selection__choice {
    background-color: $primary-color;

    cursor: default;

    float: left;

    margin-right: 5px;
    margin-top: 12px;
    min-height: 36px;
    padding: 0 5px;
  }

  .select2-selection__choice__remove {
    color: scale-color($primary-color,$lightness:50%);
    cursor: pointer;
    font-size: rem-calc(28);
    line-height: 1;

    display: inline-block;
    font-weight: bold;

    margin-right: 2px;
    position: relative;
    top: 2px;

    &:hover {
      color: $white;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

&.select2-container--focus {
  .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
  }
}

&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
  }

  .select2-selection__choice__remove {
    display: none;
  }
}
