body.single-blog,
body.single-event,
body.single-post {
  article {
    > div.row {
      @include grid-row;
      &:first-child() {
        border-top: 1px solid $medium-gray;
        padding-top: rem-calc(20);
        @include breakpoint(medium) {
          padding-top: rem-calc(80);
        }
      }
    }
    .content {
      @include grid-column(12);
      @include breakpoint(medium) {
        @include grid-col-size(7);
      }

      h3 {
        color: $gam-purple;
        font-size: $global-font-size;
        font-weight: $global-weight-medium;
        margin-bottom: rem-calc(5);
      }
    }
    header {
        @include grid-column(12);
        @include breakpoint(large) {
          @include grid-col-size(7);
        }
    }
    footer {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(40);
        @include breakpoint(medium) {
            margin-top: rem-calc(80);
        }
    }
    ul.solutions {
      list-style: none;
      margin-left: 0;
      li {
        display: inline-block;
        border-right: 1px solid $black;
        padding-right: rem-calc(10);
        margin-right: rem-calc(10);
        &:last-child {
          border-right: 0;
        }
      }
    }
    .meta {
      margin-top: rem-calc(30);
      border-bottom: 1px solid $black;
      display: inline-block;
      margin-bottom: rem-calc(50);
      font-weight: 500;
      min-width: rem-calc(150);
      color: $gam-purple;
      ul.share {
        display: inline-block;
        vertical-align: middle;
        margin-left: rem-calc(20);
        &:first-child() {
          margin-left: 0;
        }
      }
    }
    .logo {
      @include grid-column(12);
      @include breakpoint(medium) {
        @include grid-col-size(5);
      }
      @include breakpoint(large) {
        @include grid-col-size(4);
      }
      img {
        max-width: rem-calc(200);
      }
    }
    .sidebar {
      @include grid-column(12);
      @include breakpoint(medium) {
        @include grid-col-size(5);
      }
      @include breakpoint(large) {
        @include grid-col-size(4);
      }
      .related {
          margin-top: rem-calc(40);
          h2 {
              font-size: $global-font-size;
              border-bottom: 1px solid $black;
              padding: 0 0 rem-calc(10);
              margin-bottom: rem-calc(10);
          }
          &:first-child {
            margin-top: 0;
          }
      }
    }
  }
}
