section.tabbed-content {
  .row {
    @include grid-row;

    .intro {
      @include grid-column(12);
      @include breakpoint(large) {
        margin-bottom: rem-calc(30);
        @include grid-col-size(5);
        // max-width: rem-calc(380);
      }
      h2 {
        margin-bottom: rem-calc(20);
      }
    }
    .tab-wrapper {
      .tabs-content {
        clear: both;
        border: 0;
      }
      .tabs-panel,
      .accordion-content {
        @include grid-row(0);
        border: 0;
        padding: rem-calc(20 0);
        @include breakpoint(medium) {
          padding: rem-calc(60 0);
        }
        .title {
          @include grid-column(12);
          overflow: hidden;
          clear: both;
        }
        .column {
          @include grid-column(12);
          overflow: hidden;
          @include breakpoint(medium) {
            @include grid-col-size(6);
            margin-bottom: rem-calc(-20);
          }
          @include breakpoint(xlarge) {
            &:first-child {
              padding-right: rem-calc(50);
            }
            &:last-child {
              padding-left: rem-calc(50);
            }
          }
          > img {
            margin: 0 0 rem-calc(20) auto;
            display: inline-block;
            @include breakpoint(medium) {
              padding-left: rem-calc(30);
              max-width: rem-calc(400);
              width: 100%;
            }
          }
          .button {
            margin-top: rem-calc(15);
          }
          .stat {
            font-size: rem-calc(14);
            max-width: rem-calc(300);
            margin-bottom: rem-calc(30);
            @include breakpoint(large) {
              @include grid-col-pos(1);
            }
            h2 {
              font-weight: 300;
              border-bottom: 1px solid $medium-gray;
              padding-bottom: rem-calc(3);
              margin-bottom: rem-calc(8);
              font-size: rem-calc(32);
              @include breakpoint(medium) {
                font-size: rem-calc(62);
              }
            }
            p {
              font-size: $global-font-size;
              color: $gam-purple;
              font-weight: 500;
              line-height: 1.2;
            }
          }
        }
        h3 {
          color: $gam-purple;
          font-size: rem-calc(map-deep-get($header-styles, small, h6, font-size));
          @include breakpoint(medium) {
            line-height: 1.2;
            font-size: rem-calc(map-deep-get($header-styles, medium, h6, font-size));
          }
        }
        @at-root html[amp] & {
          display: block;
        }
      }
    }
  }
  body.single-case_study & {
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .tabs-wrapper {
      background: $white;
      padding-bottom: rem-calc(40);
      @extend .body-push;
    }
    .tabs-content {
      @extend .body-push;
    }
  }
}
div.expandable {
  .accordion-item:first-child > :first-child {
    border-radius: 0;
  }
  .accordion-title {
    background: $gam-purple;
    border-bottom: 0;
    color: $white;
    padding: rem-calc(10 40);
    font-size: rem-calc(map-deep-get($header-styles, small, h6, font-size));
    z-index: 3;
    @include breakpoint(medium) {
      font-size: rem-calc(map-deep-get($header-styles, medium, h6, font-size));
    }
    &:before {
      right: rem-calc(40);
      margin-top: rem-calc(-10);
    }
  }
  @include breakpoint(medium down) {
    .accordion-item {
      position: relative;
    }
  }
  .accordion-content {
    padding: 0;
    background: $white;
    padding: rem-calc(40);
    border: 0 !important;
    margin-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
    margin-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
    @include breakpoint(medium) {
      margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    }
    @include breakpoint(medium down) {
      overflow-x: auto;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        top: 0;
        bottom: 0;
        width: rem-calc(150);
        background: linear-gradient(to left, rgba($white,1) 0%, rgba($white,0) 100%);
        z-index: 2;
      }
    }
    @include breakpoint(small only) {
      &:after {
        right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      }
    }
  }
  table {
    min-width: rem-calc(900);
    border: 0;
    td,
    th,
    tr,
    thead,
    tbody {
      background-color: transparent !important;
      border: 0;
      font-size: rem-calc(15);
    }
    td,
    th {
      vertical-align: top;
      padding: rem-calc(10 5);
      &.border-left {
        border-left: 1px solid $black;
      }
    }
    tr.border-top {
      border-top: 1px solid $black;
    }
    thead {
      th {
        vertical-align: bottom;
        border-bottom: 1px solid $black;
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(20);
        img {
          height: rem-calc(25);
          width: auto;
        }
      }
    }
  }
}

ul.tabs {
  border: 0;
  border-bottom: 1px solid $medium-gray;
  margin-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
  margin-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
  @include breakpoint(medium) {
    min-width: 33.3333%;
    margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
  }
  @at-root html[amp] & {
    display: none;
  }
}
.tabs-title {
  > a {
    font-weight: $global-weight-medium;
    font-size: rem-calc(15);
    color: $black;
    padding-left: 0;
    padding-right: 0;
    margin-left: rem-calc(5);
    margin-right: rem-calc(5);
    border-bottom: 4px solid transparent;
    @include breakpoint(large) {
      margin-left: rem-calc(20);
      margin-right: rem-calc(20);
    }
    &:focus,
    &[aria-selected="true"] {
      background: transparent;
      border-bottom-color: $gam-purple;
      outline: none;
    }
  }
  &:first-child > a {
    margin-left: 0;
  }
}
.accordion-title {
  font-weight: $global-weight-medium;
  border: 0;
  border-bottom: 1px solid $light-gray;
  padding-left: 0;
  padding-right: 0;
  margin-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
  margin-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
  @include breakpoint(medium) {
    margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
  }
  &:hover,
  &:focus {
    background: transparent;
    border-color: $gam-purple;
    border-bottom-width: 2px;
  }
}
.light {
  .tabs {
    a {
      color: $white;
      opacity: 0.7;
    }
    .tabs-title > a:focus,
    .tabs-title > a[aria-selected="true"] {
      opacity: 1;
      border-bottom-color: $white;
    }
  }
  .tabs-content {
    color: $white;
  }
  div.expandable {
    .accordion-title {
      background: $white;
      color: $gam-purple;
    }
    .accordion-content {
      background: transparent;
      color: $white;
    }
  }
}
