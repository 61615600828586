// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(large);
  background: transparent;
  padding: 0;
  @at-root html[amp] & {
    display: block !important;
  }
  svg {
    display: block;
    max-width: rem-calc(125);
    max-height: rem-calc(30);
    @include breakpoint(large) {
      max-width: rem-calc(100);
      max-height: rem-calc(24);
    }
  }
  .title-bar-left {
    background: rgba(255, 255, 255, 1);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    border-bottom: 1px solid $light-gray;
    &,
    svg * {
      transition: all 0.5s ease-in-out;
    }
    > .body-push {
      margin-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
      margin-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      @include breakpoint(medium) {
        margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      }
    }
    @include breakpoint(medium) {
      &.is-anchored {
        background: rgba(255, 255, 255, 0);
        border-bottom: 0;
        @at-root header:not(.black) & {
          svg#gamma-logo-mobile {
            .st0,
            g path {
              fill: $white;
            }
          }
        }
      }
    }
    @at-root body.admin-bar &.is-stuck {
      top: rem-calc(46) !important;
      @include breakpoint(medium) {
        top: rem-calc(32) !important;
      }
    }
  }
  .title-bar-title { //Foundation class override
    order: 1;
  }
  #site-navigation-toggle { //burger menu
    order: 3;
  }
  .header-strapline-mobile { //strap + phone
    order: 2;
    text-align: center;
  }
  @media screen and (max-width:640px) {
    .header-strapline-mobile {
      order: 3;
      width: 100%;
      color: #0a0a0a;
    }
  }
  @media screen and (min-width:640px) {
    .header-strapline-mobile {
      margin-top: 0.25rem;
    }
    .title-bar-left.is-anchored .header-strapline-mobile, .title-bar-left.is-anchored .header-strapline-mobile > a {
      color: $white;
    }
  }
  .site-mobile-title {
    width: 25%;
  }
  .site-mobile-menu-cotaniner {
    width: 25%;
    order: 3;
  }
}
.desktop-menu,
.site-navigation .top-bar-left {
  @include show-for(large);
  margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
  margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
  svg {
    display: block;
    max-width: rem-calc(200);
    max-height: rem-calc(48);
    // height: auto;
  }
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float: left;
}

.nav-next {
  float: right;
}
header .sticky-container {
  position: fixed;
  width: 100%;
  z-index: 10;
  margin: 0;
}
#main-area .sticky-container { // fix for jumpNav and WeAreGamma drodown menu gap issue
  z-index: 5!important;
}

svg[id*='search-icon'] {
  .search {
    fill: none;
    stroke: $gam-purple;
  }
}

.top-bar {
  .top-bar-title {
    margin: 0;
  }
  &,
  svg * {
    transition: all 0.5s ease-in-out;
  }
  &.is-anchored {
    background: rgba(255, 255, 255, 0);
    #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r {
      > li {
        > ul.mega-sub-menu {
          margin-top: rem-calc(32);
        }
      }
    }
    &:hover {
      ul > li.highlight-lrg {
        > a {
          background: $white!important;
          color: $gam-purple!important;
          &:after {
            border-right-color: $gam-purple;
            border-bottom-color: $gam-purple;
          }
        }
      }
    }
  }
  &.mega-open,
  &:hover {
    background: $gam-purple !important;
    border-bottom: 1px solid rgba($white, 0.6);

    svg#gamma-logo-desktop {
      .st0,
      g path {
        fill: $white;
      }
    }

    svg[id*='search-icon'] {
      .search {
        fill: none;
        stroke: $white;
        stroke-width: 1.452;
      }
    }
    ul > li > a {
      color: $white;
      &:after {
        border-right-color: $white;
        border-bottom-color: $white;
      }
    }

    ul > li.highlight {
      > a {
        background: $white;
        color: $gam-purple;
        &:after {
          border-right-color: $gam-purple;
          border-bottom-color: $gam-purple;
        }
      }
    }
    ul > li.highlight-lrg { // nav is stuck (hover)
      > a {
        background: $white!important;
        color: $gam-purple!important;
        &:after {
          border-right-color: $gam-purple;
          border-bottom-color: $gam-purple;
        }
      }
    }
    #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r {
      > li {
        > a.mega-menu-link {
          &:hover {
            &:before {
              background-color: $gam-yellow;
            }
          }
        }
        a.mega-menu-link {
          color: $medium-gray;
          &:before {
            content: '';
            position: absolute;
            bottom: rem-calc(-32);
            left: 0;
            background-color: transparent;
            height: rem-calc(3);
            width: 100%;
          }
          &:after {
            border-right-color: $white;
            border-bottom-color: $white;
          }
          &:hover {
            color: $gam-yellow !important;
            &:after {
              border-right-color: $gam-yellow;
              border-bottom-color: $gam-yellow;
            }
          }
        }
        &.is-active,
        &.mega-current-menu-item,
        &.mega-current-menu-ancestor {
          > a.mega-menu-link {
            color: $white !important;
          }
        }
      }
    }
  }
  @at-root header:not(.black) & {
    &.is-anchored {
      svg#gamma-logo-desktop {
        .st0,
        g path {
          fill: $white;
        }
      }
      ul,
      #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r {
        > li {
          a {
            color: $medium-gray;
            &:after {
              border-right-color: $white;
              border-bottom-color: $white;
            }
          }
          &.is-active,
          &.mega-current-menu-item,
          &.mega-current-menu-ancestor {
            > a.mega-menu-link {
              color: $white !important;
            }
          }
          @at-root body.home & {
            > a.mega-menu-link {
              color: $white !important;
            }
            &.mega-toggle-on {
              > a.mega-menu-link {
                color: $gam-yellow !important;
                &:before {
                  background-color: $gam-yellow;
                }
              }
            }
          }
          &.highlight {
            a {
              background: $white;
              color: $gam-purple;
              &:after {
                border-right-color: $gam-purple;
                border-bottom-color: $gam-purple;
              }
            }
          }
        }
      }
      ul.desktop-menu > li {
        &.is-active,
        &.current-menu-item,
        &.current-page-ancestor {
          > a {
            color: $white !important;
          }
        }
        @at-root body.home & {
          > a {
            color: $white !important;
          }
        }
      }
      svg[id*='search-icon'] {
        .search {
          fill: none;
          stroke: $white;
          stroke-width: 1.452;
        }
      }
    }
  }
  &.is-stuck {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid $light-gray;
    @at-root body.admin-bar & {
      top: rem-calc(32) !important;
    }
    &.mega-open {
      background: $gam-purple;
      border-bottom: 1px solid rgba($white, 0.6);
    }
    #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r {
      > li {
        > a.mega-menu-link {
          &:before {
            bottom: rem-calc(-12);
          }
        }
      }
    }
    /*
    &.highlight-lrg {
      a {
        background: $white!important;
        color: $gam-purple!important;
        display: inline;
        padding: rem-calc(8 30 10)!important;
        &:after {
          border-right-color: $white;
          border-bottom-color: $white;
        }
      }
    }
    */
  }

  #mega-menu-wrap-top-bar-r {
    clear: none;
    float: left;
    position: static;
  }
  ul,
  #mega-menu-wrap-top-bar-r #mega-menu-top-bar-r {
    position: static;
    background: transparent;
    float: left;
    list-style: none;
    > li {
      &.footer-only {
        display: none;
      }
      &.is-active,
      &.mega-current-menu-item,
      &.mega-current-menu-ancestor {
        > a.mega-menu-link {
          color: $gam-purple;
        }
      }
      &:not(.mega-menu-item-has-children) {
        > a,
        > a.mega-menu-link {
          padding: rem-calc(5 15 5 15) !important;
          &:after {
            display: none;
          }
          &:hover {
            color: $gam-yellow;
          }
        }
      }
      &.mega-toggle-on {
        > a.mega-menu-link {
          color: $gam-yellow !important;
          &:after {
            border-right-color: $gam-yellow;
            border-bottom-color: $gam-yellow;
            transform: rotate(-135deg) translate(-5px, -5px);
          }
          &:before {
            background-color: $gam-yellow;
          }
        }
      }
      > a,
      > a.mega-menu-link {
        font-weight: $global-weight-medium !important;
        border-radius: rem-calc(20);
        color: $black;
        padding: rem-calc(5 15 5 15) !important;
        height: auto;
        @include breakpoint(xlarge) {
          &:after {
            content: "";
            display: inline-block;
            position: relative;
            top: rem-calc(-2);
            right: auto;
            margin-left: rem-calc(10);
            margin-top: auto;
            border: 1px solid transparent;
            border-right-color: $black;
            border-bottom-color: $black;
            width: rem-calc(10);
            height: rem-calc(10);
            transition: transform 0.2s ease-in-out;
            transform: rotate(45deg);
            vertical-align: baseline;
          }
          .mega-indicator {
            display: none !important;
          }
        }
        @include breakpoint(xxlarge) {
          padding: rem-calc(5 15 5 20) !important;
          font-size: rem-calc(16);
        }
        &:hover {
          color: $gam-yellow;
          &:after {
            border-right-color: $gam-yellow;
            border-bottom-color: $gam-yellow;
          }
        }
      }
      &.highlight {
        a {
          background: $gam-purple;
          color: $white;
          &:after {
            border-right-color: $white;
            border-bottom-color: $white;
          }
        }
      }
      &.highlight-lrg { // nav is stuck
        a {
          background: $gam-purple!important;
          color: $white!important;
          display: inline;
          padding: rem-calc(8 30 10)!important;
          &:after {
            border-right-color: $white;
            border-bottom-color: $white;
          }
        }
      }
      > ul.mega-sub-menu {
        margin-top: rem-calc(12);
      }
      &.mega-menu-grid {
        > ul.mega-sub-menu {
          @extend .body-push;
          > li.mega-menu-row {
            width: auto;
            float: none;
            display: block;
            > ul.mega-sub-menu {
              display: flex;
              justify-content: center;
              align-items: stretch;
            }
          }
          ul {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
          li {
            a.mega-menu-link {
              font-size: rem-calc(14) !important;
              padding-left: 0 !important;
              padding-right: 0 !important;
              padding-top: 0 !important;
            }
          }
        }
        li.mega-menu-column {
          width: auto !important;
          // max-width: 25%;
          float: none !important;
          flex: 0 1 auto;
          &:last-child {
            border-right: 0;
          }
          ul {
            li {
              float: none !important;
              h4 {
                font-size: rem-calc(16) !important;
                @include breakpoint(large) {
                  font-size: rem-calc(18) !important;
                }
              }
              a {
                color: $gam-yellow;
                font-size: rem-calc(14) !important;
              }
              ul {
                li {
                  a.mega-menu-link {
                    padding-left: rem-calc(12) !important;
                    position: relative;
                    &:before {
                      content: "";
                      display: block;
                      width: rem-calc(8);
                      height: rem-calc(1);
                      background: $gam-yellow;
                      position: absolute;
                      top: rem-calc(6);
                      left: 0;
                    }
                  }
                }
              }
              &.widget_text {
                margin-top: rem-calc(30);
                margin-bottom: rem-calc(10);
                min-width: rem-calc(220);
                &:first-child {
                  margin-top: 0;
                }
                img {
                  width: rem-calc(40);
                }
              }
            }
          }
        }
      }
    }
  }

  .supplementary {
    float: left;
    margin-top: rem-calc(12);
    margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    @include breakpoint(xxlarge) {
      margin-left: rem-calc(50);
    }
    ul {
      a {
        font-size: rem-calc(14);
        &:after {
          top: rem-calc(-3);
          width: rem-calc(6);
          height: rem-calc(6);
        }
      }
    }
    .widget {
      float: left;
      margin-left: rem-calc(10);
      label {
        padding: 0.5rem 0;
        @include breakpoint(large) {
          padding: 0;
        }
        line-height: 1;
        margin-right: rem-calc(10);
      }
      button {
        padding-left: 0;
        padding-right: 0;
      }
      &.widget_search {
        .input-group {
          margin-bottom: 0;
          position: relative;
        }
        .input-group-field {
          width: 0;
          border-width: 0;
          padding-left: 0;
          padding-right: 0;
          transition: all 0.4s ease-in-out;
          position: absolute;
          right: rem-calc(30);
          top: rem-calc(-8);
          border-radius: rem-calc(20);
        }
        &.hover,
        &.focussed {
          border-width: 0;
          // box-shadow: 0 0 10px 10px rgba(255,255,255,1);
          .input-group-field {
            border-width: 1px;
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);
            width: rem-calc(250);
            min-width: rem-calc(250);
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
.supplementary {
  .widget {
    button {
      background: transparent;
      color: $black;
      border: 0;
    }
  }
}
.mobile-off-canvas-menu {
  .supplementary, .eyebrow-menu {
    .menu {
      display: block;
    }
    form {
      margin-left: rem-calc(15);
    }
  }
  li.footer-only {
    display: none;
  }
  li.is-submenu-parent-item {
    font-weight: 500;
  }
  @at-root html[amp] & ul {
    li {
      position: relative;
    }
    button {
      position: absolute;
      right: rem-calc(15);
      top: rem-calc(8);
      width: rem-calc(20);
      height: rem-calc(20);
      background: $gam-purple;
      display: flex;
      justify-content: center;
      align-content: center;
      + ul {
        display: none;
      }
      &:after {
        content: "+";
        color: $white;
        line-height: 1;
      }
    }
    button.toggled-on {
      &:after {
        content: "-";
      }
      & + ul {
        display: block;
      }
    }
  }
}
.eyebrow {
  display: flex;
  flex-flow: nowrap row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*max-width:1903px;*/
  height: 75px;
  margin-top: 0px;
  top: 0px;
  padding-left: 4.6875rem;
  line-height: 75px;
  background-color: #fefefe;
  .eyebrow-nav-wrapper .widget_nav_menu {
    padding-right: 0.75rem;
  }
  @media screen and (min-width: 75em) {
    .eyebrow-nav-wrapper .widget_nav_menu {
      padding-right: 4rem;
    }
  }
  .eyebrow-menu {
    float: left;
    /*margin-top: rem-calc(12);*/
    margin-top: rem-calc(20);
    margin-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
    @include breakpoint(xxlarge) {
      margin-left: rem-calc(50);
    }

    ul {
      a {
        font-size: rem-calc(14);

        &:after {
          top: rem-calc(-3);
          width: rem-calc(6);
          height: rem-calc(6);
        }
      }
    }

    svg[id*='search-icon'] {
      .search {
        fill: none;
        stroke: $gam-purple;
        stroke-width: 1.452;
      }
    }

    .widget {
      float: left;
      margin-left: rem-calc(10);

      label {
        padding: 0.5rem 0;
        @include breakpoint(large) {
          padding: 0;
        }
        line-height: 1;
        margin-right: rem-calc(10);
      }

      button {
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        color: $black;
        border: 0;
      }

      &.widget_search {
        .input-group {
          margin-bottom: 0;
          position: relative;
        }

        .input-group-field {
          width: 0;
          border-width: 0;
          padding-left: 0;
          padding-right: 0;
          transition: all 0.4s ease-in-out;
          position: absolute;
          right: rem-calc(30);
          top: rem-calc(-8);
          border-radius: rem-calc(20);
        }

        &.hover,
        &.focussed {
          border-width: 0;
          // box-shadow: 0 0 10px 10px rgba(255,255,255,1);
          .input-group-field {
            border-width: 1px;
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);
            width: rem-calc(250);
            min-width: rem-calc(250);
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
.menu-icon {
  float: right;
  /*margin-top: rem-calc(4);*/
  margin-top: 0.45rem;
  @include breakpoint(medium) {
    @at-root header:not(.black) .is-anchored & {
      &:after {
        background: $white;
        box-shadow: 0 7px 0 $white, 0 14px 0 $white;
      }
    }
  }
}
//SUB MENU
.menu,
.is-dropdown-submenu-parent {
  position: static;
}
.dropdown.menu > li {
  > a {
    border-radius: 0;
  }
  > .is-dropdown-submenu {
    position: absolute;
    @extend .body-push;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    border: 0;
    border-top: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;
    background: $white;
    flex-direction: row;
    justify-content: center;
    left: 0 !important;
    right: 0 !important;
    width: auto;
    &.js-dropdown-active {
      display: flex !important;
    }

    .is-anchored & {
      // background: transparent;
      // border-top-color: $white;
      // border-bottom: 0;
      a {
        color: $black;
      }
    }
    li {
      display: inline-block;
      flex: 0 1 auto;
      width: auto;
    }
    a {
      font-size: rem-calc(14);
      &:after {
        display: none;
      }
    }
  }
}
.jump-nav {
  @include breakpoint(small only) {
    display: none;
  }
  &.is-stuck {
    margin-top: rem-calc(46) !important;
    @at-root body.admin-bar & {
      margin-top: rem-calc(78) !important;
    }
    @include breakpoint(large) {
      margin-top: rem-calc(68) !important;
      @at-root body.admin-bar & {
        margin-top: rem-calc(100) !important;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    border-bottom: 1px solid $light-gray;
    background: $white;
    li {
      display: inline-block;
      padding-top: rem-calc(15);
      padding-bottom: rem-calc(15);
      padding-left: rem-calc(map-get($grid-margin-gutters, small) / 2);
      padding-right: rem-calc(map-get($grid-margin-gutters, small) / 2);
      @include breakpoint(medium) {
        padding-left: rem-calc(map-get($grid-margin-gutters, medium) / 2);
        padding-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
      }
      a {
        font-size: rem-calc(15);
        font-weight: $global-weight-medium;
        color: $black;
      }
      &.active {
        a {
          color: $gam-purple;
        }
      }
    }
  }
}
.row.breadcrumb {
  /*margin-top: rem-calc(-5);*/
  top: 230px;
  z-index: 1;
  display: block;
  position: absolute;
  p.breadcrumbs {
    @include grid-column(12);
    font-size: rem-calc(12);
    color: $black;
    a {
      color: $black;
    }
  }
  .site-header.white & {
    p.breadcrumbs {
      color: $white;
      a {
        color: $white;
      }
    }
  }
  .is-stuck + & {
    display: none;
  }
}
@media screen and (max-width:1023px) {
  .row.breadcrumb {
    top: 80px;
  }
}
@media screen and (max-width:1023px) {
  nav.site-navigation {
    display: none!important;
  }
}


// fix mobile menu on webkit
.drilldown .is-drilldown-submenu {
  z-index: unset;
}
