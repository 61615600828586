body.single-partner,
#partner-reveal {
  article {
    clear: both;
    overflow: hidden;
    footer {
      @include grid-column(12);
      padding-top: rem-calc(20);
    }
  }
  .row {
    @include grid-row;
    header {
      @include grid-column(12);
    }
  }
  .content {
    @include grid-column(12);
    @include breakpoint(medium) {
      @include grid-col-size(8);
    }
  }
  .logo {
    @include grid-column(12);
    max-width: rem-calc(200);
    @include breakpoint(small only) {
      margin: 0 auto;
      float: none !important;
    }
    @include breakpoint(medium) {
      @include grid-col-size(3);
    }
    @include breakpoint(large) {
      @include grid-col-size(2);
    }
  }
  section.hero-slide .slide .content > img, 
  section.hero-slide .slide .content > picture {
    max-width: rem-calc(200);
    margin-top: rem-calc(60);
    @include breakpoint(large) {
      max-width: rem-calc(300);
      margin-top: rem-calc(150);
    }
  }
}
#partner-reveal {
  @include breakpoint(large) {
    padding: rem-calc(120 100 100);
  }
  h1 {
    font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
    @include breakpoint(medium) {
      font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
    }
  }
  .logo {
    @include breakpoint(medium) {
      @include grid-col-size(3);
    }
  }
}
