section.intro {
  overflow: hidden;
  .row {
    @include grid-row;
    max-width: none;
    @include breakpoint(large) {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      &.image {
        align-items: stretch;
      }
      &.image {
        .right {
          float: none !important;
          display: flex;
          // flex-direction: column;
          align-items: flex-end;
          max-width: rem-calc(680);
          .image {
            width: 100%;
          }
        }
      }
      &:before,
      &:after {
        content: none !important;
      }
    }
  }
  .content {
    @include grid-column(12);
    padding-bottom: rem-calc(12);
    padding-top: rem-calc(12);
    @include breakpoint(large) {
      @include grid-col-size(5);
      max-width: rem-calc(540);
      float: none;
      padding-bottom: 0;
      padding-top: 0;
    }
    .button {
      margin-top: rem-calc(25);
    }
  }
  h1,
  h2 {
    margin-bottom: rem-calc(30);
    &:last-child {
      margin-bottom: 0;
    }
  }
  h3 {
    margin-top: rem-calc(30);
  }
  .right {
    @include grid-column(12);
    h2 {
      // padding-top: rem-calc(10);
      // @at-root .single-case_study & {
      //   padding-top: 0;
      // }
    }
    form {
      padding-top: rem-calc(26);
    }
    @include breakpoint(large) {
      width: 48%;
    }
    .responsive-embed {
      max-width: rem-calc(700);
    }
    .form-row {
      display: flex;
      justify-content: flex-start;
    }
    .select2 {
      width: auto !important;
      flex: 1 1 auto;
      max-width: 75%;
      @include breakpoint(medium) {
        max-width: 85%;
      }
    }
    .select2-container--default .select2-selection--single {
      border-radius: 0;
    }
    .image {
      margin-bottom: rem-calc(-30);
      @include breakpoint(large) {
        margin-top: rem-calc(-80);
        margin-bottom: rem-calc(-80);
      }
    }
    @include breakpoint(medium) {
      .select2-container .select2-selection--single {
        height: auto;
        .select2-selection__arrow {
          width: rem-calc(40);
          top: rem-calc(4);
          b:after {
            width: rem-calc(10);
            height: rem-calc(10);
            border-width: 2px;
          }
          @at-root body.home & {
            top: rem-calc(7);
            b:after {
            width: rem-calc(20);
            height: rem-calc(20);
            }
          }
        }
      }
    }
    .select2-selection__rendered {
      color: $gam-purple;
      font-size: rem-calc(20);
      font-weight: 300;
      padding: rem-calc(5px 0);
      @include breakpoint(medium) {
        line-height: 1.2;
        font-size: rem-calc(25);
      }
      @at-root body.home & {
        font-size: rem-calc(30);
        @include breakpoint(medium) {
          font-size: rem-calc(36);
        }
      }
      > span {
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: rem-calc(60);
      }
    }
    form .button {
      margin-left: rem-calc(10);
      max-width: 20%;
      @at-root body.home & {
        @include breakpoint(medium) {
          margin-top: rem-calc(10);
          padding-top: rem-calc(12);
          padding-bottom: rem-calc(12);
          // font-size: rem-calc(20);
        }
      }
    }
    &.style-icon {
      align-self: flex-start;
      .wrapper {
        max-width: rem-calc(300);
        @include breakpoint(medium) {
          margin: 0 auto;
        }
      }
      .image {
        display: block;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: 1px solid $medium-gray;
        padding-bottom: rem-calc(10);
        img {
          width: rem-calc(150);
        }
      }
      .strapline {
        padding-top: rem-calc(10);
        font-size: rem-calc(14);
        line-height: 1.4;
        max-width: rem-calc(180);
      }
    }
    &.style-jump_menu {
      h2 {
        font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size));
        max-width: rem-calc(520);
        @include breakpoint(medium) {
          font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size));
        }
      }
    }
  }
  @at-root body.single-case_study & {
    .row.image {
      .right {
        justify-content: flex-start;
        align-items: center;
        .image {
          margin-top: 0;
          margin-bottom: 0;
          max-width: rem-calc(200);
        }
      }
    }
  }
}
body.home {
  section.intro {
    .row {
      .content {
        @include breakpoint(medium) {
          @include grid-column(9);
        }
                @include breakpoint(large) {
          @include grid-column(12);
        }
      }
    }
  }
  // .select2-results__option {
  //   color: $gam-purple;
  //   font-size: rem-calc(26);
  //   line-height: 1.2;
  //   @include breakpoint(medium) {
  //     font-size: rem-calc(34);
  //   }
  // }
  // .select2-container--open .select2-dropdown--below {
  //   border-top: 1px solid #aaa;
  //   margin-top: -1px;
  // }
}

body[data-slug="solutions"] {
  section.intro {
    .row {
      .content {
        @include breakpoint(medium) {
          @include grid-column(9);
        }
                @include breakpoint(large) {
          @include grid-column(12);
        }
      }
    }
  }
}
